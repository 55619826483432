import React from "react"
import { useTranslation } from "react-i18next"
import { OrderStatusesTypes } from "@/types/statuses.types"
import { Select } from "antd"

interface MapStatusFilterSelectProps {
  statuses: string[]
  setStatuses: (statuses: string[]) => void
}

const { Option } = Select
const MapStatusFilterSelect: React.FC<MapStatusFilterSelectProps> = ({ statuses, setStatuses }) => {
  const { t } = useTranslation()

  const statusOptions: { value: OrderStatusesTypes; text: string }[] = [
    {
      value: OrderStatusesTypes.SUBMITTED,
      text: t("common.order-status.submitted"),
    },
    { value: OrderStatusesTypes.CREATED, text: t("common.order-status.created") },
    { value: OrderStatusesTypes.TO_PICKUP, text: t("common.order-status.to_pickup") },
    { value: OrderStatusesTypes.PICKED_UP, text: t("common.order-status.toDropoff") },
    { value: OrderStatusesTypes.AT_HUB, text: t("common.order-status.atHub") },
    { value: OrderStatusesTypes.TO_DROPOFF, text: t("common.order-status.stopToDropoff") },
    { value: OrderStatusesTypes.DELIVERED, text: t("common.order-status.delivered") },
    { value: OrderStatusesTypes.RETURNING, text: t("common.order-status.returning") },
    { value: OrderStatusesTypes.RETURNED, text: t("common.order-status.returned") },
    { value: OrderStatusesTypes.CANCELLED, text: t("common.order-status.cancelled") },
  ]

  return (
    <Select
      mode="multiple"
      placeholder={t("common.order-field.status")}
      value={statuses}
      maxTagCount={2}
      popupMatchSelectWidth={false}
      allowClear={true}
      onClear={() => setStatuses([])}
      onChange={(statuses) => setStatuses(statuses)}
      style={{ minWidth: 100 }}
      showArrow
    >
      {statusOptions?.map((option, index) => (
        <Option key={index} value={option.value}>
          {option.text}
        </Option>
      ))}
    </Select>
  )
}

export default MapStatusFilterSelect
