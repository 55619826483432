import React, { useEffect, useState } from "react"
import { DispatchMapFilters } from "@/types/map"
import MoveStopDropdown from "./MoveStopDropdown"
import { useRecoilState } from "recoil"
import { dispatchRoutesAtom } from "@/atoms/mapAtom"
import { getDispatchRoutes } from "@/api/map"
import { omit } from "lodash"
import { IRoute } from "@/hooks/useCalculateRoute"

interface MoveStopWrapperProps {
  routesRef?: React.MutableRefObject<IRoute[]>
  filters: DispatchMapFilters
  stop: { id: string; orderId: string; index: number }
  callback?: (sourceOrderId: string, targetOrderId: string) => void
}

const MoveStopWrapper: React.FC<MoveStopWrapperProps> = ({
  routesRef,
  filters,
  stop,
  callback,
}) => {
  const [dispatchRoutes, setDispatchRoutes] = useRecoilState(dispatchRoutesAtom)
  const [triggerOpen, setTriggerOpen] = useState<boolean>(false)

  const fetchDispatchRoutes = async () => {
    try {
      const { data } = await getDispatchRoutes(omit(filters, "openedKeys"))
      setDispatchRoutes(data.filter((route) => route.id !== stop.orderId))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchDispatchRoutes()
  }, [filters])

  useEffect(() => {
    if (triggerOpen) {
      fetchDispatchRoutes
    }
  }, [])

  return (
    <MoveStopDropdown
      setIsOpen={setTriggerOpen}
      routesRef={routesRef}
      dispatchRoutes={dispatchRoutes}
      stop={stop}
      type={filters?.type}
      callback={callback}
    />
  )
}

export default MoveStopWrapper
