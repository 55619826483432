import { CountryCode } from "libphonenumber-js"
import { CountryKeys, DistanceUnit } from "./localization.types"
import { DefaultPaymentMethodType } from "./paymentMethod.types"
import { IProductType } from "./productTypes.types"
import { ProvidersType } from "./providers.types"
import { OrderStatusesTypes } from "./statuses.types"
import { Coordinates } from "./map"

export const DEFAULT_REASSIGN_TIME_IN_SECONDS = 15 * 60

export interface Organization {
  id: string
  name: string
  phone: string
  address: string
  email: string
  country: CountryCode
  hasStripeAccount: boolean
  hasValidPaymentMethod: boolean
  hasActiveSubscription: boolean
  enableNotifications: boolean
  cometChatEnabled: boolean
  suspendedAt?: number
}

export interface PartialOrganization {
  id: string
  name: string
  country: string
}

export interface ICreateOrgPayload {
  name: string
  phone: string
  address?: string
  email: string
  country: CountryKeys
  marketingData: Partial<OrgMarketingFormValues>
  distanceUnit?: DistanceUnit
  coordinates?: Coordinates
}

export interface OrgBusinessFormValues {
  name: string
  orgName: string
  phone: string
  email: string
  country: CountryKeys
  address?: string
  city?: string
}

export interface OrgMarketingFormValues {
  expectedOrders: number
  businessType: IProductType
  department: string
  expectedEcommerce: string
  other?: string
  cta_source?: string
  utm_medium?: string
  utm_source?: string
  utm_campaign?: string
  utm_content?: string
  utm_term?: string
}

export interface PatchOrgPayload {
  name: string
  phone: string
  email: string
}

export interface Branding {
  companyName?: string
  companyWebsiteUrl?: string
  companyLogoUrl?: string
  primaryColor?: string
  secondaryColor?: string
  width?: number
  height?: number
  textColor?: string
  platformEnabled?: boolean
  uploadLogoForm?: any
}

export interface IPreference {
  invoiceThreshold?: number
  defaultPaymentMethod?: DefaultPaymentMethodType
  excludeProviderType?: ProvidersType[]
  platformReassign?: boolean
  notificationsSettings: NotificationsSettings
  notificationsConfig: NotificationsSettings
  joinedKosmoNetwork: boolean
  reassignTime?: number
  autoassign?: {
    maxDriversPerOrder?: number
    maxDeliveriesPerDriver?: number
    reassignTime?: number
    disabled?: boolean
  }
  distanceUnit: DistanceUnit
  preparationTime?: number
  branding?: Branding
  trackingConfig?: {
    showDriverPosition?: boolean
    showDriverPhone?: boolean
    showPickupETAs?: boolean
    showDropoffETAs?: boolean
    showDriverAssignedETAs?: boolean
    supportTrackingURL?: string
    supportTrackingEmail?: string
    supportTrackingPhone?: string
    supportTrackingWhatsapp?: string
  }
}

export interface NotificationsSettings {
  merchantNotifications?: NotifiedStatusesTypes
  merchantTransporters?: TransportersSettingsType
  customerNotifications?: NotifiedStatusesTypes
  customerTransporters?: TransportersSettingsType
}

export enum Transporters {
  EMAIL = "email",
  SMS = "sms",
  WHATSAPP = "whatsapp",
}

export type TransportersSettingsType = Record<Transporters, boolean>

export type NotifiedStatusesTypes = Record<Partial<OrderStatusesTypes>, boolean>
