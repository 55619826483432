import React, { Dispatch, SetStateAction, useState } from "react"
import { Input, Menu, MenuProps, Select, message } from "antd"
import { StopDescription, DispatchRoute, OrderType } from "@/types/orders.types"
import { groupBy, isEmpty, map } from "lodash"
import { useTranslation } from "react-i18next"
import { postMoveDraftStop } from "@/api/draftorder"
import { useRecoilValue } from "recoil"
import { driversAtom } from "@/atoms/driversAtom"
import { teamsAtom } from "@/atoms/teamsAtom"
import { DisplayDriverName } from "../DriverDisplay/DisplayDriverName"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { postMoveActiveStop } from "@/api/deliveries"
import { IRoute } from "@/hooks/useCalculateRoute"

interface MoveStopDropdownProps {
  setIsOpen?: Dispatch<SetStateAction<boolean>>
  isLoading?: boolean
  dispatchRoutes: DispatchRoute[]
  stop: StopDescription
  type: OrderType
  callback?: (sourceOrderId: string, targetOrderId: string) => void
  routesRef?: React.MutableRefObject<IRoute[]>
}

type SelectItem = {
  key: string
  label: string | React.ReactNode
  children: {
    label: string | React.ReactNode
    key: string
  }[]
}

const MoveStopDropdown: React.FC<MoveStopDropdownProps> = ({
  setIsOpen,
  isLoading,
  dispatchRoutes,
  stop,
  type,
  callback,
  routesRef,
}) => {
  const { t } = useTranslation()
  const drivers = useRecoilValue(driversAtom)
  const teams = useRecoilValue(teamsAtom)

  const [searchValue, setSearchValue] = useState<string>("")
  const [isLoadingPostMove, setIsLoadingPostMove] = useState<boolean>(false)

  const byDriver = groupBy(dispatchRoutes, "driverId")

  const filterSelectOption = (item: SelectItem) => {
    const matchSearch = !!searchValue
      ? item?.key?.toLowerCase()?.includes(searchValue?.toLowerCase())
      : true
    return item.label && matchSearch
  }

  const handleMoveStop = async (targetOrderId: string) => {
    setIsLoadingPostMove(true)
    try {
      if (type === OrderType.DRAFT) {
        await postMoveDraftStop(targetOrderId, stop)
      } else if (type === OrderType.ACTIVE) {
        await postMoveActiveStop(stop.orderId, targetOrderId, stop)
      }
      if (callback) {
        callback(stop.orderId, targetOrderId)
      }
      message.success(t("common.stopMovedSuccessfully"))
    } catch (error) {
      console.error("MoveStopDropdown -> error", error)
      message.error(t("errors.common"))
    } finally {
      setIsLoadingPostMove(false)
    }
  }

  const driverItems = map(byDriver, (driverOrders, driverId) => {
    const driverInfo = drivers.find((driver) => driver.id === driverId)
    const routesColors: string[] = []
    const driverOrdersItems = driverOrders?.map((order, index) => {
      const orderRoute = routesRef?.current?.find(
        (mapRoute) => mapRoute?.id === order?.id && mapRoute?.active
      )
      if (orderRoute?.color) {
        routesColors?.push(orderRoute?.color)
      }
      return {
        label: order.orderId ? (
          <div className="flex items-center gap-x-1">
            <div style={{ width: 3, height: 12, backgroundColor: orderRoute?.color }} />{" "}
            {`${order.orderId}  | ${order?.stops} ${t("common.stops")}`}
          </div>
        ) : (
          <div className="flex items-center gap-x-1">
            <div style={{ width: 3, height: 12, backgroundColor: orderRoute?.color }} />{" "}
            {`${t("common.route")} ${index + 1} | ${order?.stops} ${t("common.stops")}`}
          </div>
        ),
        key: order.id,
        hasColor: !!orderRoute,
      }
    })
    return {
      key: `${driverId}_${driverInfo?.name}`,
      label: driverInfo ? (
        <div className="flex flex-col">
          <DisplayDriverName driver={driverInfo} activeRoutesColors={routesColors?.slice(0, 3)} />
        </div>
      ) : (
        ""
      ),
      children: driverOrdersItems?.sort((routeA, routeB) =>
        routeA?.hasColor && routeB?.hasColor ? -1 : 1
      ),
    }
  })?.filter((item) => filterSelectOption(item)) as MenuProps["items"]

  const byTeam = groupBy(
    dispatchRoutes?.filter((order) => order.teamId && !order?.driverId),
    "teamId"
  )

  const teamItems = map(byTeam, (teamOrders, teamId) => {
    const teamInfo = teams.find((team) => team.id?.toString() === teamId)
    return {
      key: `${teamId}_${teamInfo?.name}`,
      label: `${teamInfo?.name}`,
      children: teamOrders?.map((order, index) => ({
        label: order.orderId ? (
          <>{`${order.orderId}  | ${order?.stops} ${t("common.stops")}`}</>
        ) : (
          <>{`${t("common.route")} ${index + 1} | ${order?.stops} ${t("common.stops")}`}</>
        ),
        key: order.id,
      })),
    }
  })?.filter((item) => filterSelectOption(item)) as MenuProps["items"]
  const allItems = [...(driverItems || []), ...(teamItems || [])]

  return (
    <Select
      onDropdownVisibleChange={(visible) => {
        if (setIsOpen) {
          setIsOpen(visible)
        }
      }}
      placeholder={t("common.move")}
      size="small"
      placement="bottomLeft"
      dropdownStyle={{ minWidth: 250 }}
      loading={isLoading || isLoadingPostMove}
      disabled={isLoading || isLoadingPostMove || isEmpty(allItems)}
      dropdownRender={(_) => {
        return (
          <div className="flex flex-col gap-y-2">
            {!isEmpty(dispatchRoutes) && (
              <Input
                onChange={(e) => setSearchValue(e.target?.value)}
                placeholder={`${t("common.searchDriver")}...`}
                className="mb-2"
                prefix={
                  <FontAwesomeIcon
                    className={"flex-shrink-0 mr-1 text-gray-600 text-sm"}
                    icon={faMagnifyingGlass}
                  />
                }
              />
            )}
            <Menu
              items={allItems}
              triggerSubMenuAction={"click"}
              onSelect={({ key }) => {
                handleMoveStop(key)
              }}
            />
          </div>
        )
      }}
    />
  )
}

export default MoveStopDropdown
