import React, { useState } from "react"
import { TaskPin } from "@/types/map"
import UnassignedTaskMarker from "./UnassignedTaskMarker"
import { MarkerClusterer } from "@react-google-maps/api"
import UnassignedInfoWindow from "./UnassignedInfoWindow"
import { isEmpty } from "lodash"

export const SINGLE_PIN_MAX_LIMIT = 100

const clusterStyle = [
  {
    textColor: "white",
    url: "/assets/images/customIcons/location-pin.png",
    height: 20,
    width: 20,
  },
  {
    textColor: "white",
    url: "/assets/images/customIcons/location-pin.png",
    height: 20,
    width: 20,
  },
  {
    textColor: "white",
    url: "/assets/images/customIcons/location-pin.png",
    height: 20,
    width: 20,
  },
]

interface UnassignedTasksRendererProps {
  tasks: TaskPin[]
  openInfoWindowRef?: React.MutableRefObject<null | (() => void)>
}

const UnassignedTasksRenderer: React.FC<UnassignedTasksRendererProps> = ({
  tasks,
  openInfoWindowRef,
}) => {
  const [multipleTasksInfoWindow, setMultipleTasksInfoWindow] = useState<
    | {
        markers: (TaskPin | undefined)[]
        // markers: (google.maps.Marker & { isAdded?: boolean | undefined })[]
        position?: google.maps.LatLng | google.maps.LatLngLiteral | undefined
      }
    | undefined
  >(undefined)

  return (
    <>
      {!isEmpty(tasks) && tasks?.length > SINGLE_PIN_MAX_LIMIT ? (
        <MarkerClusterer
          key={"marker-clusterer"}
          options={{ minimumClusterSize: 2, zoomOnClick: false }}
          styles={clusterStyle}
          onClick={(cluster) => {
            const markers = cluster.getMarkers()
            const markerPosition = {
              lat: markers?.[0]?.getPosition()?.lat() || 0,
              lng: markers?.[0]?.getPosition()?.lng() || 0,
            }
            // setShowInfoWindow(true)
            const sameAddressTasks = markers.map((marker) => {
              return tasks?.find((task) => task.orderID === marker?.getTitle())
            })
            if (markerPosition.lat !== 0 && markerPosition.lng !== 0) {
              setMultipleTasksInfoWindow({ markers: sameAddressTasks, position: markerPosition })
            }
          }}
        >
          {(clusterer) => {
            return (
              <>
                {multipleTasksInfoWindow && (
                  <UnassignedInfoWindow
                    openInfoWindowRef={openInfoWindowRef}
                    tasks={
                      multipleTasksInfoWindow.markers?.filter(
                        (marker) => marker !== undefined
                      ) as TaskPin[]
                    }
                    position={multipleTasksInfoWindow.position}
                    handleInfoWindowClose={() => {
                      multipleTasksInfoWindow?.markers?.map((marker) => {
                        if (marker?.onPinClickClose) {
                          marker?.onPinClickClose()
                        }
                      })
                      setMultipleTasksInfoWindow(undefined)
                    }}
                    showInfoWindow={multipleTasksInfoWindow?.markers?.length > 0}
                  />
                )}
                {tasks?.map((task, index) => {
                  return (
                    <UnassignedTaskMarker
                      task={task}
                      key={index}
                      clusterer={clusterer}
                      openInfoWindowRef={openInfoWindowRef}
                    />
                  )
                })}
              </>
            )
          }}
        </MarkerClusterer>
      ) : (
        <>
          {tasks?.map((task, index) => (
            <UnassignedTaskMarker task={task} key={index} openInfoWindowRef={openInfoWindowRef} />
          ))}
        </>
      )}
    </>
  )
}

export default UnassignedTasksRenderer
