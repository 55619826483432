import { ACTIVE_ORDERS_DEFAULT_DATES, DRAFT_ORDERS_DEFAULT_DATES, IFiltersState } from "@/hooks/useOrdersFilters";
import { emptyFilters } from "@/modules/map/DispatchMap";
import { DraftOrderTableStatusFilter } from "@/types/draftOrder.types";
import { DispatchMapFilters } from "@/types/map";
import { OrderType } from "@/types/orders.types";
import { OrderStatusesTypes } from "@/types/statuses.types";
import { isEmpty, omit } from "lodash";
import { DateTime } from "luxon";
import { DATE_FILTER_BY, DateFilterType } from "./datetime";

export const LOCAL_STORAGE_FILTERS_KEY = "persistedFilters"

export function convertFiltersToDispatchMapFilters(filters: IFiltersState): DispatchMapFilters {
  return {
    statuses: filters.status ? [filters.status] : [],
    zones: filters.zoneIds || [],
    teams: filters.teams ? filters.teams.map(t => t.toString()) : [],
    drivers: filters.driversIds || [],
    is_active: filters.isActive || false,
    type: filters.plan ? OrderType.DRAFT : OrderType.ACTIVE,
    dates: filters.dates,
    [OrderType.ACTIVE]: filters.dates,
    [OrderType.DRAFT]: filters.dates,
    q: filters.q || "",
    dateFilterBy: filters.dateFilterBy,
  }
}

export function convertDispatchMapFiltersToFilters(filters: DispatchMapFilters): IFiltersState {
  return {
    isActive: filters.is_active || false,
    q: filters.q || "",
    status: filters.statuses?.[0] as OrderStatusesTypes | undefined,
    dates: filters.dates,
    zoneIds: filters.zones || [],
    teams: filters.teams.map(t => Number(t)) || [],
    driversIds: filters.drivers || [],
    plan: filters.type === OrderType.DRAFT,
    draftStatus: DraftOrderTableStatusFilter.TOTAL,
    dateFilterBy: filters.dateFilterBy || DATE_FILTER_BY.CREATION,
  }
}

export const getPersistedFilters = (orgId: string, fromMap?: boolean) => {
  let orderType = OrderType.ACTIVE
  let dateFilterBy = emptyFilters.dateFilterBy
  const previousFilters = JSON.parse(localStorage?.getItem(LOCAL_STORAGE_FILTERS_KEY) || "{}")
  // Reset filters after 6 hours of inactivity
  if (!isEmpty(previousFilters)) {
    orderType = previousFilters.type
    // Unused for now - directly managed in querystring building
    if (fromMap) {
      dateFilterBy = DATE_FILTER_BY.CREATION
    } else if (previousFilters.dateFilterBy !== undefined) {
      dateFilterBy = previousFilters.dateFilterBy
    }

    const activeDatesFilters = shouldResetDateFilters(OrderType.ACTIVE, orgId) ? { ...ACTIVE_ORDERS_DEFAULT_DATES, ttl: DateTime.now().plus({ hours: 6 }).toMillis() } : previousFilters?.[OrderType.ACTIVE]
    const draftDatesFilters = shouldResetDateFilters(OrderType.DRAFT, orgId) ? { ...DRAFT_ORDERS_DEFAULT_DATES, ttl: DateTime.now().plus({ hours: 6 }).toMillis() } : previousFilters?.[OrderType.DRAFT]

    return {
      ...previousFilters,
      dateFilterBy,
      openedKeys: [],
      [OrderType.ACTIVE]: activeDatesFilters,
      [OrderType.DRAFT]: draftDatesFilters,
      dates: orderType === OrderType.ACTIVE ? activeDatesFilters : draftDatesFilters,
      type: orderType
    }
  }

  return { ...emptyFilters, dateFilterBy, type: orderType, openedKeys: [] }
}

export const setFiltersToLocalStorage = (filters: DispatchMapFilters) => {
  localStorage?.setItem(
    LOCAL_STORAGE_FILTERS_KEY,
    JSON.stringify(omit({ ...filters, ttl: DateTime.now().plus({ hours: 6 }).toMillis(), }, ["q", "openedKeys"]))
  )
}

export const setDateAndTypeFiltersToLocalStorage = (type: OrderType, orgId: string, dateFilters?: DateFilterType, dateFilterBy?: DATE_FILTER_BY) => {
  const currentFilters = getPersistedFilters(orgId, undefined)
  setFiltersToLocalStorage({
    ...currentFilters,
    orgId,
    type,
    ...(dateFilters ? {
      [type]: {
        range: dateFilters?.range.map((date) => date?.split(" ")?.[0]),
        format: dateFilters?.format?.split(" ")?.[0],
        ttl: DateTime.now().plus({ hours: 6 }).toMillis(),
      }
    } : {}),
    ...(dateFilterBy !== undefined ? { dateFilterBy } : {}),
  })
}

export const setTypeFiltersToLocalStorage = (type: OrderType) => {
  const previousFilters = JSON.parse(localStorage?.getItem(LOCAL_STORAGE_FILTERS_KEY) || "{}")
  if (!isEmpty(previousFilters)) {
    setFiltersToLocalStorage({ ...previousFilters, type })
  }
}

export const shouldResetDateFilters = (type: OrderType, orgId: string) => {
  const previousFilters = JSON.parse(localStorage?.getItem(LOCAL_STORAGE_FILTERS_KEY) || "{}")
  const targetDateFiltersType = previousFilters?.[type]
  const ttlDate = targetDateFiltersType?.ttl ? DateTime.fromMillis(targetDateFiltersType?.ttl) : undefined

  // reset
  if (!ttlDate) {
    return true
  }

  const isTTLValid = ttlDate && ttlDate.isValid && DateTime.now() <= ttlDate

  if (isTTLValid && previousFilters?.orgId === orgId) {
    // No reset
    return false
  }

  // Reset
  return true
}
