import { atom } from "recoil"
import { IOrder, TableOrder, OrderType } from "@/types/orders.types"
import { DraftOrder } from "@/types/draftOrder.types"
import { Stop } from "@/types/stops.types"

export type DrawerOrder = (DraftOrder & { type: OrderType.DRAFT, orderID?: never }) | ((IOrder | TableOrder) & { type: OrderType.ACTIVE })

export interface dispatchPageModalsState {
  // Deprecated for now - replaced with editOrderDrawerInstance for single stop update
  editStopModal: { stop: Stop | null, stopIndex: number | null } | null,
  editOrderDrawerInstance: DrawerOrder | null,
  orderDetailsModal: { orderDetailsModalId: string | null, stopIndex?: number },
  defaultOpenAddressOrRef?: string
}

export const defaultModalsState = {
  editStopModal: null,
  editOrderDrawerInstance: null,
  orderDetailsModal: { orderDetailsModalId: null, stopIndex: undefined },
  defaultOpenAddressOrRef: ""
}


export const dispatchPageModalsAtom = atom<dispatchPageModalsState>({
  key: "dispatchPageModalsAtom",
  default: {
    editStopModal: null,
    editOrderDrawerInstance: null,
    orderDetailsModal: { orderDetailsModalId: null, stopIndex: undefined },
    defaultOpenAddressOrRef: ""
  }
})
