import { DestinationStatuses, IOrder, Location, OrderType } from "@/types/orders.types"
import { OrderStatusesTypes } from "@/types/statuses.types"
import {
  FULL_DATE_FORMAT,
  MONTH_NAME_FORMAT,
  SHORT_DATE_FORMAT,
  TIME_FORMAT,
} from "@/utils/datetime"
import { DateTime, Interval } from "luxon"
import { ProvidersType } from "@/types/providers.types"
import { TFunction } from "i18next"
import { startCase } from "lodash"
import { Stop } from "@/types/stops.types"
import { MapOrder } from "@/types/map"
import { isOrderStatusFinal, isStopStatusFinal } from "./status"
import { MerchantOrderTracking } from "@/types/clients.types"

const UNKNOWN_TIME = ""
const ONE_DAY = 24 * 60 * 60 * 1000
export const ONE_MINUTE = 60 * 1000 // Milliseconds in one minute
export const ONE_HOUR = 60 * ONE_MINUTE // Milliseconds in one hour

export const humanizeTimestamp = (timestamp: number) => {
  if (!timestamp) {
    return ""
  }
  return DateTime.fromMillis(timestamp).toFormat("HH:mm")
}

const invalidEta = (eta?: number) => {
  return !eta || eta < 0
}

const durationTill = (timestamp: number) => {
  try {
    const duration = Interval.fromDateTimes(DateTime.now(), DateTime.fromMillis(timestamp))
    return duration.length("minutes")
  } catch (e) {
    return NaN
  }
}

export const isSameDay = (timestamp1: number, timestamp2: number) => {
  const startDateRange = DateTime.fromMillis(timestamp1)
  const endDateRange = DateTime.fromMillis(timestamp2)

  return (
    startDateRange.hasSame(endDateRange, "day") &&
    startDateRange.hasSame(endDateRange, "month") &&
    startDateRange.hasSame(endDateRange, "year")
  )
}

const getDateString = (
  eta: number,
  options: {
    withDate?: boolean
    fallbackFormat?: string
    useHumanRelativeFormat?: boolean
  },
  t: TFunction
) => {
  const { withDate, fallbackFormat, useHumanRelativeFormat } = options || {}

  if (!eta || !withDate) {
    return ""
  }
  if (eta < DateTime.now().toMillis() + ONE_DAY * 7) {
    if (useHumanRelativeFormat && isSameDay(eta, DateTime.now().toMillis())) {
      // Formerly today
      return `${t("common.today")} `
    }
    if (useHumanRelativeFormat && isSameDay(eta, DateTime.now().toMillis() + ONE_DAY)) {
      return `${t("common.tomorrow")} `
    }
    return `${DateTime.fromMillis(eta).toFormat(fallbackFormat || "dd MMM -")} `
  }

  return `${DateTime.fromMillis(eta).toFormat(fallbackFormat || SHORT_DATE_FORMAT)} `
}

export const getHumanReadableDateTimeString = (timestamp: number, t: TFunction) => {
  if (!timestamp) {
    return ""
  }

  if (isSameDay(timestamp, DateTime.now().toMillis())) {
    return `${t("common.today")} ${DateTime.fromMillis(timestamp).toFormat("HH:mm")}`
  }

  return `${DateTime.fromMillis(timestamp).toFormat("dd MMM HH:mm")}`
}

const getTimeRangeString = (
  start: number,
  end: number,
  options: {
    withDate?: boolean
    weekDay?: boolean
  },
  t: TFunction
) => {
  const timeTill = durationTill(end)
  if (isNaN(timeTill)) {
    return t("common.delayed")
  }
  if (!start || !end) {
    return ""
  }

  const dateString = getDateString(start, options, t)
  return `${dateString}${humanizeTimestamp(start)} - ${humanizeTimestamp(end)}`
}

const getTimeRangeStringExplicit = (
  start: number,
  end: number,
  options: {
    withDate?: boolean
    weekDay?: boolean
  },
  t: TFunction
) => {
  const dateString = getDateString(start, options, t)
  if (!start) {
    return `${dateString}${humanizeTimestamp(end)}`
  }
  if (!end) {
    return `${dateString}${humanizeTimestamp(start)}`
  }
  return `${dateString}${humanizeTimestamp(start)} - ${humanizeTimestamp(end)}`
}

export const getPickupTime = (
  order: IOrder | MerchantOrderTracking | (Stop & { pickupAt?: any }),
  withPrefix: boolean,
  t: TFunction
) => {
  let etaString = ""
  switch (order.status) {
    case OrderStatusesTypes.CREATED:
    case OrderStatusesTypes.SUBMITTED:
    case OrderStatusesTypes.TO_PICKUP: {
      if (
        invalidEta(order.pickupStartEta) ||
        invalidEta(order.pickupEndEta) ||
        !order.pickupEndEta
      ) {
        return UNKNOWN_TIME
      }
      const withDate = !isSameDay(order.pickupEndEta, DateTime.now().toMillis())
      etaString = `${getTimeRangeString(
        order.pickupStartEta,
        order.pickupEndEta,
        {
          withDate,
        },
        t
      )}`
      break
    }
    case OrderStatusesTypes.CANCELLED:
      etaString = UNKNOWN_TIME
      break
    //picked_up & after
    default: {
      if (invalidEta(order.pickupAt) || !order.pickupAt) {
        return UNKNOWN_TIME
      }
      const withDate = !isSameDay(order.pickupAt, DateTime.now().toMillis())
      etaString = `${getDateString(order.pickupAt, { withDate }, t)}${humanizeTimestamp(
        order.pickupAt
      )}`
      break
    }
  }
  return withPrefix && etaString ? getPickupPrefix(order.status, t) + etaString : etaString
}

export const getPickupPrefix = (status: OrderStatusesTypes, t: TFunction) => {
  const prefix = [
    OrderStatusesTypes.SUBMITTED,
    OrderStatusesTypes.CREATED,
    OrderStatusesTypes.TO_PICKUP,
  ].includes(status)
    ? `${t("common.pickup")} ETA `
    : `${t("common.order-status.picked_up")} `
  return prefix
}

export const getPreOrderPickupTime = (order: IOrder, t: TFunction) => {
  return getPickupTime({ ...order, status: OrderStatusesTypes.CREATED }, false, t)
}

export const getDropoffTime = (
  t: TFunction,
  timestamps: {
    deliveryEta: number
    arrivalEta?: number
    dropoffAt?: number
    cancelledAt?: number
  },
  status: OrderStatusesTypes
) => {
  const orderETA = timestamps.arrivalEta || timestamps.deliveryEta
  let etaString

  switch (status) {
    case OrderStatusesTypes.CREATED:
    case OrderStatusesTypes.SUBMITTED:
    case OrderStatusesTypes.TO_PICKUP:
    case OrderStatusesTypes.PICKED_UP: {
      const timeToDisplay = timestamps?.dropoffAt || orderETA
      if (invalidEta(orderETA)) {
        return UNKNOWN_TIME
      }

      etaString = ` ${getDateString(timeToDisplay, { withDate: true }, t)}${humanizeTimestamp(
        timeToDisplay
      )}`
      break
    }
    // Stops dropoff time are displayed as delivered
    case OrderStatusesTypes.DELIVERED: {
      if (invalidEta(timestamps?.dropoffAt) || !timestamps?.dropoffAt) {
        return UNKNOWN_TIME
      }
      const dataString = getDateString(timestamps?.dropoffAt, { withDate: true }, t)
      etaString = `${dataString || ""}${humanizeTimestamp(timestamps?.dropoffAt)}`
      break
    }
    case OrderStatusesTypes.CANCELLED: {
      if (invalidEta(timestamps?.cancelledAt) || !timestamps?.cancelledAt) {
        return UNKNOWN_TIME
      }
      const withDate = !isSameDay(timestamps?.cancelledAt, DateTime.now().toMillis())
      etaString = `${getDateString(timestamps?.cancelledAt, { withDate }, t)}${humanizeTimestamp(
        timestamps?.cancelledAt
      )}`
      break
    }
    case OrderStatusesTypes.TO_DROPOFF:
    case OrderStatusesTypes.RETURNING:
    case OrderStatusesTypes.RETURNED:
    case OrderStatusesTypes.AT_HUB:
      if (invalidEta(orderETA)) {
        return UNKNOWN_TIME
      }
      etaString = `${getDateString(orderETA, { withDate: true }, t)}${humanizeTimestamp(orderETA)}`
      break
    default:
      etaString = UNKNOWN_TIME
      break
  }

  return etaString ?? UNKNOWN_TIME
}

export const getDropoffPrefix = (status: OrderStatusesTypes, t: TFunction) => {
  switch (status) {
    case OrderStatusesTypes.CANCELLED:
      return `${t("common.order-status.cancelled")} `
    case OrderStatusesTypes.DELIVERED:
      return `${t("common.order-status.delivered")} `
    case OrderStatusesTypes.RETURNING:
      return `${t("common.order-status.returning")} `
    case OrderStatusesTypes.RETURNED:
      return `${t("common.order-status.returned")} `
    default:
      return ``
  }
}

export const getPreOrderDropoffTime = (order: IOrder, t: TFunction) => {
  if (invalidEta(order.deliveryEta)) {
    return UNKNOWN_TIME
  }

  if (order.isScheduled) {
    const estimatedDelivery = DateTime.fromMillis(order.deliveryEta as number).toMillis()
    return `${getDateString(order.deliveryEta, { withDate: true }, t)}${humanizeTimestamp(
      estimatedDelivery
    )}`
  }

  return getDropoffTime(
    t,
    {
      deliveryEta: order?.deliveryEta,
      dropoffAt: order?.dropoffAt,
      cancelledAt: order?.cancelledAt,
    },
    OrderStatusesTypes.CREATED
  )
}

// TODO: review this function, current range time function doesn't work if one value is missing,
// but for expected arrival time it may happen
export const getDropoffByTimesNonStrict = (
  from: DateTime | undefined,
  to: DateTime | undefined,
  t: TFunction
) => {
  if (!from && !to) {
    return UNKNOWN_TIME
  }
  const fromMs = from?.toMillis() || 0
  const toMs = to?.toMillis() || 0
  if (invalidEta(fromMs) && invalidEta(toMs)) {
    return UNKNOWN_TIME
  }
  const withDate = !isSameDay(fromMs, DateTime.now().toMillis())

  return `${getTimeRangeStringExplicit(fromMs, toMs, { withDate }, t)}`
}

export const getOwnFleetPickupPrefixKey = (order: IOrder | Stop) => {
  const isPickedUp = !!order?.pickupAt && order?.pickupAt > 0
  if (
    (!isPickedUp && order?.status === OrderStatusesTypes?.CANCELLED) ||
    ("stopIndex" in order && order?.recipient?.status === DestinationStatuses?.CANCELED)
  ) {
    return ""
  } else if (isPickedUp) {
    return "common.order-status.picked_up"
    // Scheduled but not picked up
  } else if (!!order?.schedule?.pickup_at && !isPickedUp) {
    return "common.pickupTime"
    // Not scheduled and not picked up
  } else if (!order?.schedule?.pickup_at && !isPickedUp) {
    return "common.pickupETA"
  } else {
    return ""
  }
}

export const getOwnFleetPickupTime = (date: number, order: IOrder | Stop, t: TFunction) => {
  if (!date) {
    return ""
  }

  const datePrefix = t(getOwnFleetPickupPrefixKey(order)) // isPickedUp ? t("common.order-status.picked_up") : "ETA"
  const dayMonthString = getDateString(
    date,
    {
      withDate: true,
      fallbackFormat: `${MONTH_NAME_FORMAT}`,
    },
    t
  )
  return `${datePrefix} ${dayMonthString}${DateTime.fromMillis(date)?.toFormat(TIME_FORMAT)}`
}

export const getPickupTimeOrEta = (order: IOrder | Stop, t: TFunction) => {
  const validPickupAt = !!order?.pickupAt && order?.pickupAt > 0
  const etaOrTimestamp = validPickupAt
    ? order.pickupAt!
    : !!order?.schedule?.pickup_at
    ? order?.schedule?.pickup_at
    : order?.pickupEndEta

  const pickupTime =
    order?.provider !== ProvidersType.OWN_FLEET
      ? getPickupTime(order, true, t)
      : getOwnFleetPickupTime(etaOrTimestamp, order, t)
  return pickupTime
}

export const getOwnFleetDropoffPrefixKey = (order: IOrder | Stop, dropoff: Location) => {
  const isOrderCancelled = order?.status === OrderStatusesTypes.CANCELLED
  const isDropoffCompleted =
    isStopStatusFinal(dropoff?.status) &&
    dropoff.status !== DestinationStatuses.CANCELED &&
    !!order?.dropoffAt &&
    !!order?.dropoffAt

  if (!isOrderCancelled && !isDropoffCompleted) {
    return "ETA"
  } else if (isOrderCancelled && !isDropoffCompleted) {
    return "common.cancelled"
  } else {
    return ""
  }
}

export const getStopOrOrderDropoffTime = (
  order: IOrder | Stop,
  isStop: boolean,
  dropoff: Location,
  t: TFunction
) => {
  if (isStop && !dropoff) {
    return UNKNOWN_TIME
  }

  const orderETA = (order as IOrder)?.arrival?.plannedArrivalAt || order?.deliveryEta

  let dropoffTime
  if (isStop && (!!dropoff?.dropoffEta || !!dropoff?.finishedAt)) {
    const stopIsOngoing =
      dropoff?.status === undefined
        ? false
        : [
            DestinationStatuses.CREATED,
            DestinationStatuses.EMPTY,
            DestinationStatuses.TO_DROPOFF,
          ].includes(dropoff.status)

    dropoffTime = getDropoffTime(
      t,
      {
        deliveryEta: dropoff?.dropoffEta || 0,
        dropoffAt: dropoff?.finishedAt || order?.dropoffAt || dropoff?.dropoffEta,
        cancelledAt: order?.cancelledAt,
      },
      dropoff?.status !== undefined &&
        [
          DestinationStatuses.DELIVERED,
          DestinationStatuses.CANCELED,
          DestinationStatuses.RETURNED,
          DestinationStatuses.EMPTY,
        ].includes(dropoff.status)
        ? OrderStatusesTypes.DELIVERED
        : order.status
    )
    dropoffTime = dropoffTime && stopIsOngoing ? `ETA ${dropoffTime}` : dropoffTime
  } else if (!!orderETA || !!order?.dropoffAt) {
    const timeToUse = order?.dropoffAt ? order?.dropoffAt! : orderETA || 0
    dropoffTime =
      order?.provider === ProvidersType.OWN_FLEET
        ? `${t(getOwnFleetDropoffPrefixKey(order, dropoff))}${DateTime.fromMillis(
            timeToUse
          )?.toFormat(" dd MMM - HH:mm")}`
        : `${getDropoffPrefix(order.status, t)} ${getDropoffTime(
            t,
            {
              deliveryEta: orderETA || 0,
              dropoffAt: order?.dropoffAt,
              cancelledAt: order?.dropoffAt,
            },
            dropoff?.status !== undefined &&
              [
                DestinationStatuses.DELIVERED,
                DestinationStatuses.CANCELED,
                DestinationStatuses.RETURNED,
                DestinationStatuses.EMPTY,
              ].includes(dropoff.status)
              ? OrderStatusesTypes.DELIVERED
              : order.status
          )}`
  }

  return dropoffTime ?? ""
}

export const getArrivalTime = (order: IOrder, t: TFunction) => {
  if (!order?.arrival?.plannedArrivalAt) {
    return UNKNOWN_TIME
  }

  if (order.status === OrderStatusesTypes.DELIVERED && order.arrival.finishedAt) {
    const dateString = getDateString(order.arrival.finishedAt, { withDate: true }, t)
    return `${t("common.order-status.arrived")} ${dateString} ${DateTime.fromMillis(
      order.arrival.finishedAt
    ).toFormat(TIME_FORMAT)}`
  }

  const dateString = getDateString(order.arrival.plannedArrivalAt, { withDate: true }, t)
  return `ETA ${dateString} ${DateTime.fromMillis(order.arrival.plannedArrivalAt).toFormat(
    TIME_FORMAT
  )}`
}

export const getMapOrderArrivalTime = (order: MapOrder) => {
  if (!order?.arr?.pla || (order.t === OrderType.ACTIVE && isOrderStatusFinal(order.s))) {
    return UNKNOWN_TIME
  }

  return `${DateTime.fromMillis(order.arr.pla).toFormat(TIME_FORMAT)}`
}

export const getScheduleTime = (
  schedule: number | undefined,
  options: { dateFormat?: string; withHumanDate?: boolean; withPrefix?: boolean },
  t: TFunction
): string | undefined => {
  if (!schedule) {
    return ""
  }

  const { withHumanDate = false, dateFormat = MONTH_NAME_FORMAT, withPrefix = false } = options
  const prefix = withPrefix ? startCase(t("common.pickupTime")) + ":" : ""
  const scheduleDateTime = DateTime.fromMillis(schedule)

  if (!scheduleDateTime || !scheduleDateTime?.isValid) {
    return ""
  }

  if (withHumanDate) {
    const dateString = getDateString(
      schedule,
      {
        withDate: true,
        fallbackFormat: dateFormat,
      },
      t
    )
    return `${prefix} ${dateString} ${scheduleDateTime.toFormat(TIME_FORMAT)}`
  }

  return `${prefix} ${scheduleDateTime.toFormat(FULL_DATE_FORMAT)}`
}

export const getRecipientDisplayedTime = (
  orderType: OrderType,
  recipient: Location,
  t: TFunction
): string | undefined => {
  if (!recipient) {
    return undefined
  }

  if (recipient.finishedAt) {
    return DateTime.fromMillis(recipient.finishedAt)?.toFormat("HH:mm")
  }

  const expectedArrival =
    !!recipient?.expectedArrival &&
    recipient?.expectedArrival?.to &&
    recipient?.expectedArrival?.from
      ? getDropoffByTimesNonStrict(
          DateTime.fromMillis(recipient?.expectedArrival.from),
          DateTime.fromMillis(recipient?.expectedArrival.to),
          t
        )
      : undefined
  const plannedArrivalAt =
    orderType === OrderType.ACTIVE
      ? undefined
      : recipient?.plannedArrivalAt && recipient?.plannedArrivalAt > 0
      ? DateTime.fromMillis(recipient?.plannedArrivalAt)?.toFormat("HH:mm")
      : undefined
  const dropoffETA = recipient?.dropoffEta
    ? DateTime.fromMillis(recipient.dropoffEta)?.toFormat("HH:mm")
    : undefined

  const displayedTime = expectedArrival ?? plannedArrivalAt ?? dropoffETA
  return displayedTime
}
