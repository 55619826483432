import { DispatchMapData, OrderGraph } from "@/types/map";
import { OrderType, DispatchRoute } from "@/types/orders.types";
import { DateTime } from "luxon";
import { atom } from "recoil";

export const emptyOrderGraph = (): OrderGraph => ({
  t: 0,
  c: 0,
  f: 0,
  r: 0,
  tr: 0,
  tw: 0,
  o: [],
})

export const defaultMapState = {
  driverPins: {},
  taskPins: {},
  graph: {
    unnassigned: emptyOrderGraph(),
    teams: [],
    thirdParty: emptyOrderGraph(),
  },
  type: OrderType.ACTIVE,
  lastUpdatedAt: DateTime.now().minus({ hours: 1 })
}
export const mapAtom = atom<DispatchMapData>({
  key: "mapAtom",
  default: defaultMapState
})

export const mapTreeOpenKeysAtom = atom<string[]>({
  key: "mapTreeOpenKeys",
  default: []
})

export const dispatchRoutesAtom = atom<DispatchRoute[]>({
  key: "dispatchRoutesAtom",
  default: []
})
