import React from "react"
import { getDisplayAddress } from "@/utils/addresses"
import { getRecipientDisplayedTime } from "@/utils/order-time-calculations"
import { last } from "lodash"

import { ShortenedTask } from "@/types/map"
import { Typography } from "antd"
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Image from "next/image"
import OrderStatusTag from "@/modules/shared/Statuses/OrderStatusTag"
import { OrderStatusesTypes } from "@/types/statuses.types"
import { useTranslation } from "react-i18next"
import DestinationStatusTag from "@/modules/active-orders/shared/DestinationStatusTag"
import { StopType, OrderType } from "@/types/orders.types"
import StopTypeBadge from "@/UI/DataDisplay/StopTypeBadge"
import { formatShortLocationToLocation } from "@/utils/stopsFormatter"

interface MapStopsListProps {
  tasks: ShortenedTask[]
  orderType: OrderType
  // TODO: Remove the dependency in the future
  onOpen: () => void
  isOpen: boolean
  orderStatus?: OrderStatusesTypes
}

const { Text } = Typography

const MapStopsList: React.FC<MapStopsListProps> = ({
  tasks,
  orderType,
  isOpen,
  onOpen,
  orderStatus,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="flex flex-col w-full overflow-y-auto">
        <div className="flex gap-x-2">
          <div className="flex items-center justify-center w-5 h-5 bg-kosmoLightGray rounded-md">
            <Image
              className="cursor-pointer"
              src="/assets/images/multi-location.svg"
              alt="logo"
              priority={true}
              width={16}
              height={16}
            />
          </div>
          <div className="flex w-full justify-between">
            <div
              className="flex w-fit gap-x-1 items-center hover:underline"
              onClick={(e) => {
                e.stopPropagation()
                onOpen()
              }}
            >
              <p className="flex flex-shrink-0 my-auto font-medium text-black">
                {tasks?.length} {t("common.stops")?.toLowerCase()}
              </p>
              <FontAwesomeIcon
                size="xs"
                className="flex-shrink-0"
                icon={isOpen ? faChevronDown : faChevronRight}
              />
            </div>
            {orderType === OrderType.ACTIVE && orderStatus && (
              <div className="flex w-4/12 justify-end text-end">
                <OrderStatusTag status={orderStatus as any} />
              </div>
            )}
          </div>
        </div>
        {isOpen ? (
          <div className="flex w-full flex-col gap-y-3 mt-4 mb-2 pl-2">
            {tasks?.map((task, taskIdx) => {
              const formattedRecipient = formatShortLocationToLocation(task.r)
              const displayedTime = getRecipientDisplayedTime(orderType, formattedRecipient, t)

              return (
                <div className="grid grid-cols-12 items-center">
                  <div className="col-span-1">
                    <p className="text-sm text-gray-600">{taskIdx + 1}</p>
                  </div>
                  <div className="flex flex-col col-span-11 leading-5">
                    <div className="flex w-full">
                      <div className="flex gap-x-2 w-8/12 items-center">
                        <Text
                          className="flex flex-shrink-0 font-medium text-black capitalize"
                          ellipsis
                        >
                          {formattedRecipient.firstName} {formattedRecipient.lastName}
                        </Text>
                        {formattedRecipient.type === StopType.PICKUP && <StopTypeBadge />}
                      </div>
                      <div className="flex w-4/12 justify-end">
                        <p>
                          {orderType === OrderType.ACTIVE && (
                            <DestinationStatusTag
                              destinationStatus={formattedRecipient.status as any}
                            />
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="flex w-full">
                      <div className="flex w-8/12 items-center">
                        <Text
                          className="text-gray-500 whitespace-nowrap text-ellipsis overflow-hidden"
                          ellipsis
                        >
                          {getDisplayAddress(formattedRecipient.location, true)}
                        </Text>
                      </div>
                      {!!displayedTime && (
                        <div className="flex w-4/12 justify-end items-center">
                          <p className="text-sm text-gray-500 text-end">{displayedTime}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <p className="text-gray-500" style={{ fontSize: 13 }}>
            {getDisplayAddress(formatShortLocationToLocation(last(tasks)?.r!)?.location, true)}
          </p>
        )}
      </div>
    </>
  )
}

export default MapStopsList
