import React from "react"
import { Tag } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleDot,
  faCircleCheck,
  faCircleXmark,
  faLocationDot,
  faCirclePlus,
  faWarehouse,
  faPersonArrowDownToLine,
} from "@fortawesome/free-solid-svg-icons"
import { OrderStatusesTypes } from "@/types/statuses.types"
import { useTranslation } from "react-i18next"

interface OrderStatusTagProps {
  status: OrderStatusesTypes
}

// TODO: Awful, create a specific Tag container component to avoid changing each at same time ...
const OrderStatusTag: React.FC<OrderStatusTagProps> = ({ status }) => {
  const { t } = useTranslation()
  switch (status) {
    case OrderStatusesTypes.CANCELLED:
      return (
        <Tag
          className="m-0 flex items-center w-fit"
          color="#D8E6FD"
          style={{ borderRadius: "8px" }}
        >
          <div className="flex flex-row items-center">
            <FontAwesomeIcon
              className="flex-shrink-0 h-3  mr-1 text-rose-600 text-sm"
              icon={faCircleXmark}
            />
            <p className="text-black m-0">{t("common.order-status.cancelled")}</p>
          </div>
        </Tag>
      )
    case OrderStatusesTypes.TO_PICKUP:
      return (
        <Tag
          className="m-0 flex items-center w-fit"
          color="#D8E6FD"
          style={{ borderRadius: "8px" }}
        >
          <div className="flex flex-row items-center">
            <FontAwesomeIcon
              className="flex-shrink-0 h-3  mr-1 text-blue-600 text-sm"
              icon={faCircleDot}
            />
            <p className="text-black m-0">{t("common.order-status.toPickup")}</p>
          </div>
        </Tag>
      )
    case OrderStatusesTypes.PICKED_UP:
      return (
        <Tag
          className="m-0 flex items-center w-fit"
          color="#D8E6FD"
          style={{ borderRadius: "8px" }}
        >
          <div className="flex flex-row items-center">
            <FontAwesomeIcon
              className="flex-shrink-0 h-3  mr-1 text-pink-500 text-sm"
              icon={faLocationDot}
            />
            <p className="text-black m-0">{t("common.order-status.toDropoff")}</p>
          </div>
        </Tag>
      )
    case OrderStatusesTypes.AT_HUB:
      return (
        <Tag
          className="m-0 flex items-center w-fit"
          color="#D8E6FD"
          style={{ borderRadius: "8px" }}
        >
          <div className="flex flex-row items-center">
            <FontAwesomeIcon className="flex-shrink-0 h-3  mr-1 text-sm" icon={faWarehouse} />
            <p className="text-black m-0">{t("common.order-status.toDropoff")}</p>
          </div>
        </Tag>
      )
    case OrderStatusesTypes.TO_DROPOFF:
      return (
        <Tag
          className="m-0 flex items-center w-fit"
          color="#D8E6FD"
          style={{ borderRadius: "8px" }}
        >
          <div className="flex flex-row items-center">
            <FontAwesomeIcon
              className="flex-shrink-0 h-3  mr-1 text-pink-500 text-sm"
              icon={faLocationDot}
            />
            <p className="text-black m-0">{t("common.order-status.toDropoff")}</p>
          </div>
        </Tag>
      )
    case OrderStatusesTypes.DELIVERED:
      return (
        <Tag
          className="m-0 flex items-center w-fit"
          color="#D8E6FD"
          style={{ borderRadius: "8px" }}
        >
          <div className="flex flex-row items-center">
            <FontAwesomeIcon
              className="flex-shrink-0 h-3  mr-1 text-green-500 text-sm"
              icon={faCircleCheck}
            />
            <p className="text-black m-0">{t("common.order-status.delivered")}</p>
          </div>
        </Tag>
      )
    case OrderStatusesTypes.RETURNING:
      return (
        <Tag
          className="m-0 flex items-center w-fit"
          color="#D8E6FD"
          style={{ borderRadius: "8px" }}
        >
          <div className="flex flex-row items-center">
            <FontAwesomeIcon
              className="flex-shrink-0 h-3  mr-1 text-rose-600 text-sm"
              icon={faPersonArrowDownToLine}
            />
            <p className="text-black m-0">{t("common.order-status.returning")}</p>
          </div>
        </Tag>
      )
    case OrderStatusesTypes.RETURNED:
      return (
        <Tag
          className="m-0 flex items-center w-fit"
          color="#D8E6FD"
          style={{ borderRadius: "8px" }}
        >
          <div className="flex flex-row items-center">
            <FontAwesomeIcon
              className="flex-shrink-0 h-3  mr-1 text-green-500 text-sm"
              icon={faCircleCheck}
            />
            <p className="text-black m-0">{t("common.order-status.returned")}</p>
          </div>
        </Tag>
      )
    case OrderStatusesTypes.SUBMITTED:
      return (
        <Tag
          className="m-0 flex items-center w-fit"
          color="#FCEBCD"
          style={{ borderRadius: "8px" }}
        >
          <div className="flex items-center">
            <FontAwesomeIcon
              className="flex-shrink-0 h-3 mr-1 text-yellow-500 text-sm"
              icon={faCirclePlus}
            />
            <p className="text-black m-0">{t("common.order-status.submitted")}</p>
          </div>
        </Tag>
      )
    case OrderStatusesTypes.CREATED:
    default:
      return (
        <Tag
          className="m-0 flex items-center w-fit"
          color="#FCEBCD"
          style={{ borderRadius: "8px" }}
        >
          <div className="flex flex-row items-center">
            <FontAwesomeIcon
              className="flex-shrink-0 h-3  mr-1 text-yellow-500 text-sm"
              icon={faCirclePlus}
            />
            <p className="text-black m-0">{t("common.order-status.created")}</p>
          </div>
        </Tag>
      )
  }
}

export default OrderStatusTag
