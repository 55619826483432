import { IProviderDetails } from "@/types/organization-providers"
import {
  DeliveryTypeLabel,
  NetworkDeliveryTypeLabel,
  ServerDeliveryType,
} from "../types/deliveryTypes.types"
import { DateTime } from "luxon"
import { Issue } from "@/types/orders.types"
import { IssueTypes } from "@/types/issues.types"
import { ProvidersType } from "@/types/providers.types"
import { OrderStatusesTypes } from "@/types/statuses.types"
import { putDeliveryRoute } from "@/api/deliveries"
import { KosmoDirections } from "@/hooks/useCalculateRoute"
import { isOrderStatusFinal } from "./status"

export const updateDeliveryRoute = async (orderId: string, stops: number[][], directions: KosmoDirections): Promise<void> => {
  try {
    const payload = {
      stops,
      routes: directions
    }

    const stringifiedRoute = JSON.stringify(payload)
    putDeliveryRoute(orderId, stringifiedRoute)
  } catch (error) {
    console.log(error)
  }
}

export const getDeliveryTypeLabel = (deliveryType?: ServerDeliveryType, schedule?: any) => {
  let scheduleType

  switch (deliveryType) {
    case ServerDeliveryType.INSTANT:
      if (schedule) {
        scheduleType = DeliveryTypeLabel.SCHEDULED
        break
      }
      scheduleType = DeliveryTypeLabel.INSTANT
      break
    case ServerDeliveryType.FOURHOURS:
      scheduleType = DeliveryTypeLabel.FOURHOURS
      break
    case ServerDeliveryType.NEXT_DAY:
      scheduleType = DeliveryTypeLabel.NEXT_DAY
      break
    case ServerDeliveryType.NEXT_THREE_DAYS:
      scheduleType = DeliveryTypeLabel.NEXT_THREE_DAYS
      break
    case ServerDeliveryType.EMPTY:
      scheduleType = DeliveryTypeLabel.INSTANT
      break
    default:
      break
  }

  return scheduleType
}

export const getTimeOfDayToken = (dateTime: DateTime) => {
  return dateTime.hour < 12 ? "morning" : "evening"
}

export const isLaterThanTomorrow = (dateTime: DateTime) => {
  return dateTime.startOf("day") > DateTime.now().plus({ days: 1 }).startOf("day")
}

export const getKosmoNetworkDeliveryLabels = (
  deliveryType: ServerDeliveryType,
  deliveryDate?: number
) => {
  let dateTime
  if (deliveryDate && deliveryDate > 0) {
    dateTime = DateTime.fromMillis(deliveryDate)
  }

  switch (deliveryType) {
    case ServerDeliveryType.INSTANT:
      return NetworkDeliveryTypeLabel.INSTANT
    case ServerDeliveryType.FOURHOURS:
      return NetworkDeliveryTypeLabel.FOURHOURS
    case ServerDeliveryType.NEXT_DAY:
      if (dateTime?.isValid && isLaterThanTomorrow(dateTime)) {
        return `${dateTime.toFormat("cccc")}` // ${getTimeOfDayToken(dateTime)}
      }
      return NetworkDeliveryTypeLabel.NEXT_DAY
    case ServerDeliveryType.NEXT_THREE_DAYS:
      if (dateTime?.isValid) {
        return `${dateTime.toFormat("cccc")}` // ${getTimeOfDayToken(dateTime)}
      }
      return NetworkDeliveryTypeLabel.NEXT_THREE_DAYS
    default:
      return ""
  }
}

export const TWO_HOURS_EST = 7200000
export const HALF_HOUR_EST = 1800000
export const FIFTEEN_MINUTES_EST = 900000

export const isExpressDelivery = (type: ServerDeliveryType | null | undefined) => {
  return type === ServerDeliveryType.INSTANT || type === ServerDeliveryType.FOURHOURS || !type
}

export const isStandardDelivery = (type: ServerDeliveryType | null | undefined) => {
  return type === ServerDeliveryType.NEXT_DAY || type === ServerDeliveryType.NEXT_THREE_DAYS
}

export const isExpressDeliveryFilter = (p: IProviderDetails | null | undefined) => {
  return p?.isStandardDelivery === false || p?.isStandardDelivery === undefined
}

export const isStandardDeliveryFilter = (p: IProviderDetails | null | undefined) => {
  return p?.isStandardDelivery === true
}

export const isOrderReassignable = (status: OrderStatusesTypes, provider: ProvidersType, deliveryType: ServerDeliveryType = ServerDeliveryType.INSTANT, issue?: Issue) => {
  const isStatusReassignable = [OrderStatusesTypes.SUBMITTED, OrderStatusesTypes.CREATED].includes(
    status
  ) || (!isOrderStatusFinal(status) && provider === ProvidersType.OWN_FLEET)

  const isDeliveryTypeReassignable = [
    ServerDeliveryType.INSTANT,
    ServerDeliveryType.FOURHOURS,
  ].includes(deliveryType)
  const isIssueReassignable = issue?.issueType === IssueTypes.NOT_ACCEPTED || !issue?.issueType
  const is3Pl = ![ProvidersType.OWN_FLEET, ProvidersType.KOSMO].includes(provider)
  const courriersReassignable =
    is3Pl && isStatusReassignable && isDeliveryTypeReassignable && isIssueReassignable

  const ownFleetReassignable = ProvidersType.OWN_FLEET === provider && isStatusReassignable

  return courriersReassignable || ownFleetReassignable
}
