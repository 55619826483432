import OwnDriverNameAndStatus from "@/UI/DataDisplay/OwnDriverCard/OwnDriverNameAndStatus"
import { Driver } from "@/types/drivers.types"
import { DispatchMapFilters } from "@/types/map"
import { EnrichedTeam } from "@/types/teams.types"
import { Zone } from "@/types/zones.types"
import { Select } from "antd"
import React, { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"

interface MapTeamDriverZonesFiltersProps {
  teams: EnrichedTeam[]
  zones: Zone[]
  drivers: Driver[]
  onDriverChange: (selectedDrivers: string[], isClear?: boolean) => void
  onTeamChange: (selectedTeams: string[], isClear?: boolean) => void
  mapFilters: DispatchMapFilters
  setMapFilters: Dispatch<SetStateAction<DispatchMapFilters>>
}

const { Option } = Select
const MapTeamDriverZoneFilters: React.FC<MapTeamDriverZonesFiltersProps> = ({
  teams,
  zones,
  drivers,
  mapFilters,
  onDriverChange,
  onTeamChange,
  setMapFilters,
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex gap-x-3">
      <Select
        mode="multiple"
        placeholder={t("common.zones")}
        value={mapFilters.zones}
        onChange={(zones) => setMapFilters({ ...mapFilters, zones })}
        style={{ width: 200 }}
        showArrow
        maxTagCount="responsive"
        showSearch={false}
      >
        {zones.map((zone) => (
          <Option key={zone.id} value={zone.id}>
            {zone.name}
          </Option>
        ))}
      </Select>
      <Select
        mode="multiple"
        placeholder={t("common.teams")}
        value={mapFilters.teams}
        onChange={(teams) => onTeamChange(teams, false)}
        style={{ width: 200 }}
        showArrow
        maxTagCount="responsive"
        showSearch
        optionFilterProp="label"
        allowClear
        onClear={() => onTeamChange([], true)}
      >
        {teams.map((team) => (
          <Option key={team.id} value={team.id} label={team.name}>
            {team.name}
          </Option>
        ))}
      </Select>
      <Select
        mode="multiple"
        placeholder={t("common.drivers")}
        value={mapFilters.drivers}
        onChange={(selectedDrivers) => onDriverChange(selectedDrivers, false)}
        style={{ width: 200 }}
        showArrow
        maxTagCount="responsive"
        showSearch
        optionFilterProp="label"
        allowClear
        onClear={() => onDriverChange([], true)}
      >
        {drivers.map((driver) => (
          <Option key={driver.id} value={driver.id} label={driver.name}>
            <div className="w-full flex justify-between gap-x-2" title={driver.name}>
              <OwnDriverNameAndStatus
                driver={driver}
                driverNameClassName="grow text-ellipsis overflow-hidden whitespace-nowrap"
              />
            </div>
          </Option>
        ))}
      </Select>
    </div>
  )
}

export default MapTeamDriverZoneFilters
