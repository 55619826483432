import React, { useState } from "react"
import Image from "next/image"

import RuleFormModalButton from "@/modules/profile/DispatchSettings/RuleFormModalButton/RuleFormModalButton.container"
import { IDispatchRule } from "@/types/dispatchRules.types"
import {
  faArrowRightFromBracket,
  faCirclePlus,
  faMagnifyingGlass,
  faUsers,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Divider, Input, Select, Space } from "antd"
import { useTranslation } from "react-i18next"
import { Team } from "@/types/teams.types"
import { vehicleImage } from "@/utils/providers"
import { Driver } from "@/types/drivers.types"
import DisabledTooltip from "@/UI/DisabledTooltip"
import { isEmpty } from "lodash"
import { AssignmentTypes } from "@/types/draftOrder.types"
import { is3PLsEnabledSelector } from "@/atoms/userAtom"
import { useRecoilValue } from "recoil"
import { SelectCommonPlacement } from "antd/lib/_util/motion"
import OwnDriverNameAndStatus from "@/UI/DataDisplay/OwnDriverCard/OwnDriverNameAndStatus"
import { useFlags } from "flagsmith/react"
import { sortDrivers } from "@/utils/drivers"

interface AssignOrderSelectProps {
  onClear?: () => void
  teams: Team[]
  drivers: Driver[]
  dispatchRules?: IDispatchRule[]
  handleSelectDriverOrTeam: (reassignTarget: string) => void
  handleAssignmentSelect: (teamId: number, assignmentType: AssignmentTypes) => Promise<void> | void
  handleUnassign?: () => Promise<void>
  loading?: null | string
  disabledText?: string | undefined
  listHeight?: number
  dropdownValue?: string
  placement?: SelectCommonPlacement
  compact?: boolean
  disableBlastLimitModal?: boolean
  placeholder?: string
}

const { Option, OptGroup } = Select

const AssignOrderSelect: React.FC<AssignOrderSelectProps> = ({
  onClear,
  teams,
  drivers,
  dispatchRules,
  loading,
  disabledText: disabled,
  handleSelectDriverOrTeam,
  handleUnassign,
  handleAssignmentSelect,
  listHeight,
  dropdownValue,
  placement,
  compact,
  placeholder,
}) => {
  const { t } = useTranslation()
  const flags = useFlags(["show_team_in_assign_select"])

  const [searchValue, setSearchValue] = useState<string>("")
  const is3PlsEnabled = useRecoilValue(is3PLsEnabledSelector)

  const matchingDrivers = sortDrivers(
    drivers.filter((driver) => {
      const matchSearch = searchValue
        ? driver?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())
        : true
      return matchSearch
    })
  )

  return (
    <>
      <DisabledTooltip enable={!!disabled && !loading} label={disabled} placement={"bottomLeft"}>
        <Select
          id="draft-order-rule-select"
          {...(compact
            ? {
                placeholder: (
                  <div className="flex h-full items-center">
                    <p className="text-sm text-center">{placeholder || t("common.assign")}</p>
                  </div>
                ),
              }
            : {
                placeholder: (
                  <div className="flex w-full justify-center gap-x-2 items-center text-center">
                    <FontAwesomeIcon icon={faArrowRightFromBracket} />
                    <p>{placeholder || t("common.assign")}</p>
                  </div>
                ),
              })}
          {...(placement ? { placement } : {})}
          allowClear={handleUnassign ? false : !!onClear}
          onClear={onClear}
          size={compact ? "small" : "middle"}
          className={`w-full text-sm draft-order-rule-selector ${
            compact ? "compact-assign-select" : ""
          }`}
          onChange={async (value) => {
            if (value === AssignmentTypes.EMPTY && handleUnassign) {
              handleUnassign()
            } else if (value === AssignmentTypes.BLAST) {
              await handleAssignmentSelect(Number(0), AssignmentTypes.BLAST)
            } else {
              handleSelectDriverOrTeam(value)
            }
          }}
          popupMatchSelectWidth={false}
          disabled={!!disabled}
          listHeight={listHeight || 600}
          dropdownStyle={{ minWidth: 300 }}
          {...(dropdownValue
            ? {
                value:
                  compact && dropdownValue?.length > 15
                    ? `${dropdownValue?.slice(0, 12)}...`
                    : dropdownValue,
              }
            : { value: null })}
          loading={loading === "rule"}
          maxTagTextLength={6}
          dropdownRender={(menu) => (
            <>
              {!isEmpty(drivers) && (
                <Input
                  onChange={(e) => setSearchValue(e.target?.value)}
                  placeholder={`${t("common.searchDriver")}...`}
                  className="mb-2"
                  prefix={
                    <FontAwesomeIcon
                      className={"flex-shrink-0 mr-1 text-gray-600 text-sm"}
                      icon={faMagnifyingGlass}
                    />
                  }
                />
              )}
              {menu}
              {dispatchRules && (
                <>
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <RuleFormModalButton
                      button={
                        <div className="flex cursor-pointer">
                          <FontAwesomeIcon
                            className="flex flex-shrink-0 h-3 mx-auto my-auto text-kosmoBlue ml-1 mr-2"
                            icon={faCirclePlus}
                          />
                          <p>{t("profile.EmptySettingBox.createNewRule")}</p>
                        </div>
                      }
                    />
                  </Space>
                </>
              )}
            </>
          )}
        >
          {!!handleUnassign && (
            <Option
              defaultSelectedKeys={[]}
              selectedKeys={[]}
              id="smart-assignment-menu"
              label={t("common.unassign")}
              value={AssignmentTypes.EMPTY}
              key={"unassign"}
              className="ant-menu-submenu-title font-semibold"
              onClick={() => handleUnassign()}
            >
              <p className={"ant-menu-submenu-title font-semibold"}>{t("common.unassign")}</p>
            </Option>
          )}
          <Option
            id="autoassign-all-menu"
            label={t("common.autoassign")}
            value={AssignmentTypes.BLAST}
            key={AssignmentTypes.BLAST}
          >
            <p className={"ant-menu-submenu-title font-semibold"}>{t("common.autoassign")}</p>
          </Option>
          {!isEmpty(teams) && (
            // This part is a hack to keep the value names in selector while hiding the options
            <OptGroup label={<p>{t("common.autoassignToTeams")}</p>}>
              {teams?.map((team) => (
                <Option
                  key={team?.id}
                  value={`team_${team?.id}`}
                  className={`w-full ${
                    flags.show_team_in_assign_select.enabled ? "visible" : "hidden"
                  }`}
                >
                  <div className="flex gap-x-1 items-center">
                    <FontAwesomeIcon
                      className="text-gray-700"
                      icon={faUsers}
                      width={25}
                      height={25}
                    />
                    <p className="w-fit capitalize">{team?.name}</p>
                  </div>
                </Option>
              ))}
            </OptGroup>
          )}
          {!isEmpty(matchingDrivers) && (
            <OptGroup label={<p>{t("common.drivers")}</p>}>
              {matchingDrivers?.map((driver) => {
                return (
                  <Option key={driver?.id!} value={driver?.id}>
                    <div className="flex items-center gap-x-1">
                      <Image
                        key={driver?.id}
                        src={vehicleImage(driver?.vehicleType)}
                        priority={true}
                        width={25}
                        height={25}
                      />
                      <OwnDriverNameAndStatus driver={driver} />
                    </div>
                  </Option>
                )
              })}
            </OptGroup>
          )}
          {(is3PlsEnabled || !isEmpty(dispatchRules)) && dispatchRules && (
            <OptGroup label={t("profile.DispatchSettings.dispatchRules")}>
              {is3PlsEnabled && <Option value={""}>{t("common.cheapest")}</Option>}
              {!isEmpty(dispatchRules) &&
                dispatchRules?.map((rule) => (
                  <Option value={rule.ruleId} key={rule.ruleId} disabled={rule.disabled}>
                    {rule.name}
                  </Option>
                ))}
            </OptGroup>
          )}
        </Select>
      </DisabledTooltip>
    </>
  )
}

export default AssignOrderSelect
