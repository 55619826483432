import React, { useState } from "react"
import { TaskPin } from "@/types/map"
import { getDisplayAddress } from "@/utils/addresses"
import { faClose, faPen } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { InfoWindow } from "@react-google-maps/api"
import { Button, Pagination } from "antd"
import { useTranslation } from "react-i18next"

interface UnassignedInfoWindowProps {
  tasks: TaskPin[]
  position: google.maps.LatLng | google.maps.LatLngLiteral | undefined
  showInfoWindow: boolean
  handleInfoWindowClose: () => void
  openInfoWindowRef?: React.MutableRefObject<null | (() => void)>
}

const UnassignedInfoWindow: React.FC<UnassignedInfoWindowProps> = ({
  tasks,
  showInfoWindow,
  handleInfoWindowClose,
  position,
  openInfoWindowRef,
}) => {
  const { t } = useTranslation()
  const [markerIndex, setMarkerIndex] = useState<number>(0)

  if (tasks?.length === 0) {
    return <></>
  }

  const task = tasks[markerIndex]

  const onCloseInfoWindow = () => {
    handleInfoWindowClose()
    if (openInfoWindowRef?.current) {
      openInfoWindowRef.current = null
    }
  }

  return (
    <>
      {showInfoWindow && (
        <InfoWindow
          onCloseClick={onCloseInfoWindow}
          options={{
            pixelOffset: new window.google.maps.Size(20, -25),
          }}
          position={position}
        >
          <div className="info-window mt-3 flex flex-col gap-y-2">
            <div className="absolute right-3 top-2 cursor-pointer" onClick={onCloseInfoWindow}>
              <FontAwesomeIcon icon={faClose} />
            </div>
            {/* Custom styling for the InfoWindow */}
            {tasks?.length > 1 && task && task?.onPinClickOpen && (
              <div
                className="cursor-pointer underline"
                onClick={() => {
                  if (task?.onPinClickOpen) {
                    task?.onPinClickOpen()
                  }
                }}
              >
                {t("common.route")}
              </div>
            )}
            {(!!task?.recipient?.orderReferenceId || task?.onPinEditStopClick) && (
              <div className="flex items-center justify-between">
                {!!task?.recipient?.orderReferenceId && (
                  <div className="info-header">
                    <h2>Ref: {task.recipient.orderReferenceId}</h2>
                  </div>
                )}
                <div className="flex gap-x-2 justify-between mr-2">
                  {!!task?.orderID &&
                    task?.adjustStopComponent &&
                    task?.adjustStopComponent({
                      id: task?.recipient?.orderReferenceId || "",
                      index: markerIndex,
                      orderId: task?.orderID,
                    })}
                  {task.onPinEditStopClick && (
                    <Button
                      onClick={task.onPinEditStopClick}
                      shape="circle"
                      size="small"
                      className="flex items-center justify-center bg-gray-100"
                    >
                      <FontAwesomeIcon
                        className="text-gray-400"
                        height={10}
                        width={10}
                        icon={faPen}
                      />
                    </Button>
                  )}
                </div>
              </div>
            )}
            <div className="info-content flex flex-col gap-y-4">
              {(!!task?.recipient?.firstName || !!task?.recipient?.lastName) && (
                <p className="capitalize">
                  <b>{t("common.recipient")}:</b> {task?.recipient?.firstName}{" "}
                  {task?.recipient?.lastName}
                </p>
              )}
              {!!task?.recipient?.location?.address && (
                <p className="capitalize">
                  <b>{t("common.address")}:</b> {getDisplayAddress(task?.recipient?.location, true)}
                </p>
              )}
              {!!task?.recipient?.phone && (
                <p>
                  <b>{t("common.phone")}:</b> {task?.recipient?.phone}
                </p>
              )}
            </div>
            {tasks?.length > 1 && (
              <div className="flex w-full justify-end">
                <Pagination
                  className="mt-3"
                  simple
                  current={markerIndex + 1}
                  total={tasks.length}
                  pageSize={1}
                  size="small"
                  onChange={(page) => setMarkerIndex(page - 1)}
                />
              </div>
            )}
          </div>
        </InfoWindow>
      )}
    </>
  )
}

export default UnassignedInfoWindow
