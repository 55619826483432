import axios from "@/modules/shared/AxiosInterceptor"
import { DispatchMapData, DispatchMapFilters } from "@/types/map"
import { OrderType, DispatchRoute } from "@/types/orders.types"
import { DATE_FILTER_BY, getDateTimeMillis } from "@/utils/datetime"
import { isEmpty } from "lodash"
import { DateTime } from "luxon"

const buildMapFiltersQueryString = (filters: DispatchMapFilters): string => {
  if (isEmpty(filters)) {
    return ""
  }

  const dailyStartRangeDate = DateTime.fromFormat(
    filters?.dates?.range?.[1] || "",
    filters?.dates?.format
  ).startOf("day")
  const dailyStartRange =
    dailyStartRangeDate && dailyStartRangeDate.isValid
      ? dailyStartRangeDate.toFormat(filters?.dates?.format)
      : filters?.dates?.range?.[0]

  const dailyRange = [dailyStartRange || "", filters?.dates?.range?.[1] || ""] as [string, string]

  const status =
    filters.statuses && filters.statuses?.length > 0 ? `&status=${filters.statuses.join(",")}` : ""
  const to =
    dailyRange?.[1] !== undefined
      ? `&to=${getDateTimeMillis(dailyRange?.[1], filters?.dates?.format, true)}`
      : ""
  const from =
    dailyRange?.[0] !== undefined
      ? `&from=${getDateTimeMillis(dailyRange?.[0], filters?.dates?.format)}`
      : ""
  const type = filters.type !== undefined ? `&type=${filters.type}` : ""
  const is_active = filters.is_active !== undefined ? `&is_active=${filters.is_active}` : ""
  const zones = filters.zones !== undefined ? `&zones=${filters.zones}` : ""
  const teams = filters.teams !== undefined ? `&teams=${filters.teams}` : ""
  const drivers = filters.teams !== undefined ? `&drivers=${filters.drivers}` : ""
  const q = filters.q !== undefined && filters.q !== "" ? `&q=${encodeURIComponent(filters.q)}` : ""
  const openedKeys = filters.openedKeys !== undefined ? `&openedKeys=${filters.openedKeys}` : ""
  const dateFilterBy =
    filters.type === OrderType.DRAFT // Enforce created_at
      ? `&dateFilterBy=${DATE_FILTER_BY.CREATION}`
      : ""
  const queryString = `?${status}${to}${from}${type}${zones}${teams}${drivers}${is_active}${q}${openedKeys}${dateFilterBy}`
  return queryString
}

export async function getMapData(filters: DispatchMapFilters, signal?: AbortSignal) {
  const baseUrl = `/map`
  const queryString = buildMapFiltersQueryString(filters)
  const url = `${baseUrl}${queryString}`
  return axios
    .get<DispatchMapData>(url, { signal })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export async function getDispatchRoutes(filters: DispatchMapFilters, signal?: AbortSignal) {
  const baseUrl = `/routes`
  const queryString = buildMapFiltersQueryString(filters)
  const url = `${baseUrl}${queryString}`
  return axios
    .get<DispatchRoute[]>(url, { signal })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}
