import React, { Dispatch, SetStateAction } from "react"
import { GenerateNewRouteFunc } from "@/hooks/useCalculateRoute"
import { Graph, GraphIterable, MapOrder, RoutesDirections } from "@/types/map"
import { ServerDeliveryType } from "@/types/deliveryTypes.types"
import {
  formatToPickup,
  formatMultipleDropoffs,
  formatShortLocationToLocation,
  formatShortenedSenderToLocation,
} from "@/utils/stopsFormatter"
import { useTranslation } from "react-i18next"
import { faList } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Dropdown, Button } from "antd"
import { StopType } from "@/types/orders.types"

interface MapDropDownActionsProps {
  orderGraph: Graph
  onCollapse: () => void
  removeAllRoutes: () => void
  generateNewRoute: GenerateNewRouteFunc
  setSelectedRowsKeys: Dispatch<SetStateAction<string[]>>
  isShowAllRoutesDisabled: boolean
}

const MapDropdownActions: React.FC<MapDropDownActionsProps> = ({
  orderGraph,
  onCollapse,
  removeAllRoutes,
  generateNewRoute,
  setSelectedRowsKeys,
  isShowAllRoutesDisabled,
}) => {
  const { t } = useTranslation()
  const graphIterable = new GraphIterable(orderGraph)
  const onShowAllRoutes = () => {
    const newSelectedRoutes: MapOrder[] = []
    for (const order of graphIterable) {
      const sender = formatShortenedSenderToLocation(order.sn)
      const parsedDirections: RoutesDirections = JSON.parse(order.rd || "{}")

      const dropoffs = formatMultipleDropoffs(
        order?.tt.map((t) => formatShortLocationToLocation(t.r)),
        ServerDeliveryType.INSTANT,
        true
      )
      const destinationPoints = dropoffs.slice()

      if (order.arr !== undefined) {
        destinationPoints.push({
          name: order.arr.c.n ?? "",
          phone: order.arr.c.p ?? "",
          latitude: order.arr.l.lat ?? 0,
          longitude: order.arr.l.lon ?? 0,
          address: order.arr.l.a ?? "",
          type: StopType.ARRIVAL,
        })
      }

      const newStopsOrder = [
        [sender.location?.latitude || 0, sender.location?.longitude || 0],
        ...destinationPoints?.map((destination) => [
          destination?.latitude || 0,
          destination?.longitude || 0,
        ]),
      ]

      generateNewRoute(
        order.oid,
        formatToPickup(sender, ServerDeliveryType.INSTANT),
        dropoffs,
        parsedDirections?.routes
          ? { ...parsedDirections, type: order?.t, newStopsOrder }
          : undefined
      )
      newSelectedRoutes?.push(order)
    }
    setSelectedRowsKeys(newSelectedRoutes?.map((route) => route.id))
  }

  const onHideAllRoutes = () => {
    removeAllRoutes()
    setSelectedRowsKeys(() => [])
  }

  const items = [
    {
      label: (
        <div onClick={onCollapse} className="select-none cursor-pointer">
          {t("map.MapDropdownActions.collapseAll")}
        </div>
      ),
      key: "collapseAll",
    },
    {
      label: (
        <div onClick={onHideAllRoutes} className="select-none cursor-pointer">
          {t("map.MapDropdownActions.hideRoutes")}
        </div>
      ),
      key: "hideRoutes",
    },
  ]
  if (!isShowAllRoutesDisabled) {
    items.push({
      label: (
        <div onClick={onShowAllRoutes} className="select-none cursor-pointer">
          {t("map.MapDropdownActions.showAllRoutes")}
        </div>
      ),
      key: "showRoutes",
    })
  }

  return (
    <Dropdown menu={{ items }} trigger={["click"]} className="map-dropdown-actions">
      <Button size="middle">
        <FontAwesomeIcon icon={faList} style={{ fontSize: 12 }} />
      </Button>
    </Dropdown>
  )
}

export default MapDropdownActions
