import { atom } from "recoil"
import { MapProps } from "@/modules/shared/Map/Map"

export const defaultGlobalMapState = {
  compact: false,
  routes: [],
  isMulti: false,
  driver: undefined,
  drivers: [],
  zones: [],
  selectedZone: undefined,
  tasks: [],
  originMapMarker: undefined,
  destinationMapMarker: undefined,
  endMapMarker: undefined,
  strictSetBounds: false,
  onMarkerChange: undefined,
  from: undefined,
}

export const globalMapAtom = atom<{ current: MapProps, prev: MapProps | null }>({
  key: "globalMapAtom",
  default: { current: defaultGlobalMapState, prev: null }
})
