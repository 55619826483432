import React, { useEffect, useState } from "react"
import { userAtom } from "@/atoms/userAtom"
import { DisplayProvider, SemiBoldLabelText } from "@/UI"
import { Checkbox, Select } from "antd"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import FormItem from "antd/lib/form/FormItem"
import TeamsCouriersRankList from "./TeamsCouriersRankList"
import { ProvidersType } from "@/types/providers.types"
import ProviderTag from "../../ProvidersList/components/ProviderTag"
import { isEmpty } from "lodash"
import { IProviderDetails } from "@/types/organization-providers"
import { useTeams } from "@/hooks/useTeams"

interface TeamsCouriersMultiSelect {
  name: string
  label?: string
  withTeamsOnly: boolean
  withRanking: boolean
  handleReorderCouriers?: (fields: { couriers: (ProvidersType | string)[] }) => void
  existingRuleCouriers?: (ProvidersType | string)[]
  activeCouriers: (IProviderDetails & { active: boolean })[]
}

const sortCouriers = (providers: (IProviderDetails & { active: boolean })[]) => [
  ...providers.filter(({ active }) => active),
  ...providers.filter(({ active }) => !active),
]

const TeamsCouriersMultiSelect: React.FC<TeamsCouriersMultiSelect> = ({
  name,
  label,
  withTeamsOnly,
  withRanking,
  handleReorderCouriers,
  existingRuleCouriers,
  activeCouriers,
}) => {
  const { t } = useTranslation()
  const { preference } = useRecoilValue(userAtom)
  const { teams } = useTeams()
  const [selectedCouriers, setSelectedCouriers] = useState<(ProvidersType | string)[]>(
    existingRuleCouriers || []
  )

  useEffect(() => {
    if (handleReorderCouriers) {
      handleReorderCouriers({ couriers: selectedCouriers })
    }
  }, [selectedCouriers])

  const filteredActiveCouriers = activeCouriers
    .filter((providerDetail) => !preference?.excludeProviderType?.includes(providerDetail.name))
    .map((provider) => provider.name)

  const teamsOptions = {
    label: "Teams",
    options: teams?.map((team) => {
      const isSelected = selectedCouriers.includes(team.name as ProvidersType)

      return {
        value: team.name,
        label: (
          <div className="flex space-x-1 items-center" key={team.name}>
            <div className="flex space-x-2 items-center">
              <Checkbox checked={isSelected} />
              <p>{team.name}</p>
            </div>
          </div>
        ),
        key: `team_${team.name}`,
      }
    }),
  }

  const couriersOptions = {
    label: "Couriers",
    options: sortCouriers(activeCouriers).map((courier, index) => {
      const isSelected = selectedCouriers.includes(courier.name)
      const isDisabled = !filteredActiveCouriers.includes(courier.name)
      return {
        value: courier.name,
        label: (
          <div className="flex space-x-1 items-center" key={index}>
            <div className="flex space-x-2 items-center">
              <Checkbox checked={isSelected} disabled={isDisabled} />
              <DisplayProvider provider={courier.name} fontStyle="font-semibold text-sm" />
            </div>
          </div>
        ),
        key: `courier_${courier.name}_${index}`,
        disabled: isDisabled,
      }
    }),
  }

  const options = withRanking
    ? [teamsOptions, couriersOptions]
    : withTeamsOnly
    ? [teamsOptions]
    : [couriersOptions]

  return (
    <>
      <FormItem
        name={name}
        label={label}
        className="cursor-pointer"
        rules={[{ required: true, message: "You must select at least one provider" }]}
      >
        <Select
          mode="multiple"
          placeholder={t("profile.CreateRuleForm.selectTeamsCouriers")}
          size="large"
          className="w-full !cursor-pointer text-sm"
          value={selectedCouriers}
          allowClear
          options={options}
          tagRender={({ value }) => {
            const isProvider = activeCouriers.find((activeCourier) => activeCourier.name === value)
            return (
              <ProviderTag
                type={!!isProvider ? "provider" : "team"}
                className="cursor-pointer"
                provider={value}
                name={value}
              />
            )
          }}
          onChange={(options) => setSelectedCouriers(options)}
        />
      </FormItem>
      {withRanking && !isEmpty(selectedCouriers) && (
        <div>
          <SemiBoldLabelText>
            {t("profile.CreateRuleForm.rankSelectedProviders")}
            <span className="font-light text-gray-400">
              {" "}
              {t("profile.CreateRuleForm.dragToRank")}
            </span>
          </SemiBoldLabelText>
          <TeamsCouriersRankList
            providers={selectedCouriers}
            allCouriersList={activeCouriers}
            setSelectedProviders={setSelectedCouriers}
          />
        </div>
      )}
    </>
  )
}

export default TeamsCouriersMultiSelect
