import React from "react"
import Image from "next/image"
import OwnDriverNameAndStatus from "@/UI/DataDisplay/OwnDriverCard/OwnDriverNameAndStatus"
import { Driver } from "@/types/drivers.types"
import { vehicleImage } from "@/utils/providers"
import { isEmpty } from "lodash"

interface DisplayDriverNameProps {
  driver: Driver
  activeRoutesColors?: string[]
}

export const DisplayDriverName: React.FC<DisplayDriverNameProps> = ({
  driver,
  activeRoutesColors,
}) => {
  return (
    <div className="flex gap-x-2">
      {driver?.vehicleType && (
        <>
          {!isEmpty(activeRoutesColors) && (
            <div className="flex flex-col gap-y-1 justify-center">
              {activeRoutesColors?.map((color) => (
                <div className="h-1.5 w-1.5 rounded-full" style={{ background: color }}></div>
              ))}
            </div>
          )}
          <div className="flex min-w-[24px]">
            <Image src={vehicleImage(driver?.vehicleType)} priority={true} width={24} height={24} />
          </div>
        </>
      )}
      <div className="flex gap-x-2 relative">
        <OwnDriverNameAndStatus driver={driver} />
      </div>
    </div>
  )
}
