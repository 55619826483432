import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Input, Select, message, InputNumber, ConfigProvider } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDollarSign, faFileAlt } from "@fortawesome/free-solid-svg-icons"
import KosmoButton from "@/UI/Buttons/KosmoButton"
import { dispatchRulesPriority } from "@/constants/dispatchRules"
import TeamsCouriersMultiSelect from "./components/TeamsCouriersMultiSelect"
import {
  IDispatchRule,
  DispatchRuleType,
  RuleFormObject,
  DispatchCourierType,
  DispatchRuleCouriers,
} from "@/types/dispatchRules.types"
import { useDispatchRule } from "@/hooks/useDispatchRule"
import { isEmpty } from "lodash"
import { ServerDeliveryType } from "@/types/deliveryTypes.types"
import { getDeliveryTypeLabel } from "@/utils/delivery.utils"
import { VehicleType } from "@/types/vehicle.types"
import { PROVIDER_DETAILS } from "@/constants/providerDetails"
import { useRecoilValue } from "recoil"
import { localizationAtom } from "@/atoms/localizationAtom"
import { IProviderDetails } from "@/types/organization-providers"
import { VehicleTypeFormItem } from "@/UI/Forms/FormItems/VehicleTypeFormItem"
import { isKosmoNetworkSelector, userAtom } from "@/atoms/userAtom"
import { ProvidersType } from "@/types/providers.types"
import { AssignmentTypes, assignmentTypeNames } from "@/types/draftOrder.types"
import { useTeams } from "@/hooks/useTeams"
import { Team } from "@/types/teams.types"
import { getSingleRuleCouriers } from "@/atoms/dispatchRulesAtom"
import { DEFAULT_REASSIGN_TIME_IN_SECONDS } from "@/types/organizations.types"

const { Option } = Select

const deliveryTypeOptions = Object.values(ServerDeliveryType).map((value) => ({
  value,
  label: getDeliveryTypeLabel(value),
}))

interface createOrUpdateRuleFormProps {
  type: "create" | "update"
  existingRule?: IDispatchRule
  closeModal: () => void
}

const CreateOrUpdateRuleForm: React.FC<createOrUpdateRuleFormProps> = ({
  existingRule,
  type,
  closeModal,
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { locale } = useRecoilValue(localizationAtom)
  const isKosmoNetwork = useRecoilValue(isKosmoNetworkSelector)
  const { teams } = useTeams()
  const { createDispatchRule, isLoading } = useDispatchRule()
  const { preference } = useRecoilValue(userAtom)

  const [rulePriority, setRulePriority] = useState<DispatchRuleType | undefined>(
    DispatchRuleType.RANK
  )
  const [selectedDeliveryType, setSelectedDeliveryType] = useState<ServerDeliveryType | null>(
    ServerDeliveryType.INSTANT
  )
  const [selectedVehicleType, setSelectedVehicleType] = useState<VehicleType | null>(null)
  const ruleCouriers = useRecoilValue(getSingleRuleCouriers(existingRule?.id))

  useEffect(() => {
    if (!isEmpty(existingRule)) {
      setRulePriority(existingRule?.priority)
      setSelectedDeliveryType(existingRule?.deliveryType || ServerDeliveryType.EMPTY)
      const assignmentType = existingRule?.couriers?.[0]?.assignmentType || AssignmentTypes.BLAST
      form.setFieldsValue({
        disabled: existingRule?.disabled,
        couriers: ruleCouriers || [],
        priority: existingRule?.priority,
        name: existingRule?.name,
        maxPrice: existingRule?.maxPrice ? existingRule?.maxPrice / 100 : 0,
        deliveryType: existingRule?.deliveryType || ServerDeliveryType.INSTANT,
        vehicleType: existingRule?.vehicleType,
        assignmentType: assignmentType,
        reassignTime:
          (existingRule?.reassignTime ||
            preference.reassignTime ||
            DEFAULT_REASSIGN_TIME_IN_SECONDS) / 60,
      })
    } else {
      form.setFieldsValue({
        priority: DispatchRuleType.RANK,
        deliveryType: ServerDeliveryType.INSTANT,
        assignmentType: AssignmentTypes.BLAST,
        reassignTime: (preference.reassignTime || DEFAULT_REASSIGN_TIME_IN_SECONDS) / 60,
      })
      setRulePriority(DispatchRuleType.RANK)
    }
  }, [])

  useEffect(() => {
    const currentCouriers = form.getFieldValue("couriers")
    if (rulePriority !== DispatchRuleType.RANK) {
      form.setFieldsValue({
        couriers:
          currentCouriers?.filter((courier: string) =>
            Object.keys(ProvidersType).includes(courier)
          ) || [],
      })
    }
  }, [rulePriority])

  const locallyActiveProviders: (IProviderDetails & { active: boolean })[] = useMemo(() => {
    const isActiveProvider = (provider: IProviderDetails) => {
      const matchDeliveryType = !!selectedDeliveryType
        ? provider.deliveryTypes.includes(selectedDeliveryType)
        : true
      const matchVehicleType = !!selectedVehicleType
        ? provider.vehicles.includes(selectedVehicleType)
        : true
      const matchCountry = provider.countries.includes(locale.toUpperCase())

      return matchDeliveryType && matchVehicleType && matchCountry
    }

    return PROVIDER_DETAILS?.map((provider) => ({
      ...provider,
      active: isActiveProvider(provider),
    }))?.filter((provider) => provider?.name !== ProvidersType.OWN_FLEET && provider.active)
  }, [locale, PROVIDER_DETAILS, selectedDeliveryType, selectedVehicleType])

  const assignmentTypesOptions = [AssignmentTypes.BLAST, AssignmentTypes.MANUAL].map(
    (assignmentType) => ({
      key: assignmentType,
      value: assignmentType,
      label: <p>{t(assignmentTypeNames[assignmentType])}</p>,
    })
  )

  const onFinish = async (rule: RuleFormObject) => {
    try {
      const formattedCouriers = rule?.couriers
        ?.map((courier) => {
          let enrichedCourier: undefined | Team | (IProviderDetails & { active: boolean }) =
            locallyActiveProviders?.find((provider) => provider.name === courier)
          // Provider type
          if (enrichedCourier) {
            return {
              provider: courier,
              type: DispatchCourierType.THIRD_PARTY,
              assignmentType: AssignmentTypes.THREE_PL,
            }
          }
          enrichedCourier = teams?.find((team) => team.name === courier)
          if (enrichedCourier) {
            return {
              provider: ProvidersType.OWN_FLEET,
              type: DispatchCourierType.OWN_FLEET,
              courierId: enrichedCourier?.id,
              assignmentType: rule?.assignmentType,
            }
          }
          return undefined
        })
        ?.filter((courier) => courier !== undefined) as DispatchRuleCouriers[]

      await createDispatchRule(
        {
          ...rule,
          providers: rule?.couriers?.filter((courier) =>
            Object.keys(ProvidersType).includes(courier)
          ) as ProvidersType[],
          deliveryType: selectedDeliveryType || rule.deliveryType,
          maxPrice: rule.maxPrice * 100,
          couriers: formattedCouriers,
          reassignTime: Number(rule.reassignTime) * 60,
        },
        existingRule?.id
      )

      message.success(
        existingRule?.id ? "Successfully updated rule" : "Successfully created the rule"
      )

      closeModal()
    } catch (error) {
      message.error("Error creating the rule")
    }
  }

  const onRadioClick = (value: VehicleType) => {
    if (selectedVehicleType && value === selectedVehicleType) {
      setSelectedVehicleType(null)
      form.setFieldValue("vehicleType", null)
    }
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            controlHeight: 32,
          },
          InputNumber: {
            controlHeight: 32,
          },
          Input: {
            controlHeight: 32,
          },
          Radio: {
            controlHeight: 34,
          },
        },
      }}
    >
      <Form
        id={type === "create" ? "create-rule-form" : "update-rule-form"}
        form={form}
        layout="vertical"
        className="space-y-3"
        validateTrigger="onSubmit"
        labelAlign="left"
        onFinish={onFinish}
      >
        <Form.Item
          name={"priority"}
          label={t("profile.CreateRuleForm.definePriority")}
          rules={[{ required: true, message: "Please select a priority" }]}
          className="mb-0"
        >
          <Select
            placeholder={t("profile.CreateRuleForm.chooseYourPriority")}
            className="w-full text-sm"
            onChange={(value) => setRulePriority(value)}
          >
            {dispatchRulesPriority
              ?.filter((rule) => rule.isEnabled(isKosmoNetwork))
              .map((rule, index) => (
                <Option value={rule.value} key={index}>
                  {rule.title}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {(rulePriority || isKosmoNetwork) && (
          <>
            <div className="flex items-center space-x-3 w-full">
              <Form.Item
                label={t("common.order-field.deliveryType")}
                name={"deliveryType"}
                className="mb-0 basis-1/2"
              >
                <Select
                  className="text-sm draft-order-rule-selector w-full"
                  placeholder={t("common.order-field.deliveryType")}
                  options={deliveryTypeOptions?.filter(
                    (deliveryType) => deliveryType?.value !== ServerDeliveryType.EMPTY
                  )}
                  popupMatchSelectWidth={false}
                  value={selectedDeliveryType}
                  onChange={(value) => setSelectedDeliveryType(value)}
                />
              </Form.Item>
              <VehicleTypeFormItem
                label={t("common.vehicle")}
                name="vehicleType"
                selectedVehicleType={selectedVehicleType}
                setSelectedVehicleType={setSelectedVehicleType}
                onRadioClick={onRadioClick}
                className="mb-0 basis-1/2"
              />
            </div>
            <TeamsCouriersMultiSelect
              name="couriers"
              label={t("profile.DispatchSettings.teamsAndCouriers")}
              withTeamsOnly={rulePriority === DispatchRuleType.ZONE}
              withRanking={rulePriority === DispatchRuleType.RANK}
              handleReorderCouriers={form.setFieldsValue}
              existingRuleCouriers={ruleCouriers}
              activeCouriers={locallyActiveProviders}
            />
            <div className="flex w-full space-x-2">
              {rulePriority === DispatchRuleType.RANK && (
                <Form.Item
                  label={t("common.assignmentType")}
                  name={"assignmentType"}
                  className="w-1/2 mb-0 pr-1"
                >
                  <Select
                    className="text-sm w-full"
                    options={assignmentTypesOptions}
                    defaultValue={assignmentTypesOptions[0]?.value}
                  />
                </Form.Item>
              )}
              <Form.Item
                className="w-1/2 mb-0"
                name={"maxPrice"}
                label={t("profile.CreateRuleForm.maxDeliveryFee")}
              >
                <InputNumber
                  autoComplete="off"
                  className="w-full"
                  min={0}
                  controls={true}
                  prefix={<FontAwesomeIcon icon={faDollarSign} color="#9CA3AF" className="mr-2" />}
                  placeholder={t("profile.CreateRuleForm.enterMaxDeliveryFeeAmount")}
                />
              </Form.Item>
            </div>
          </>
        )}
        <Form.Item
          name="reassignTime"
          className="w-1/2 pr-1"
          label={t("profile.DispatchSettings.reassignTime")}
        >
          <Input
            prefix=" "
            name="reassignTime"
            placeholder={"15"}
            type="number"
            className="withAddonInput"
            addonAfter="Minutes"
            style={{ borderRadius: "none !important" }}
          />
        </Form.Item>
        <Form.Item
          name={"name"}
          label={t("profile.CreateRuleForm.nameYourRule")}
          rules={[{ required: true, message: "Please define a name" }]}
        >
          <Input
            autoComplete="off"
            prefix={<FontAwesomeIcon icon={faFileAlt} color="#9CA3AF" className="mr-2" />}
            placeholder={t("profile.CreateRuleForm.enterRuleName")}
          />
        </Form.Item>
        <div className="flex w-full justify-end !mt-4 space-x-4">
          <KosmoButton type="default" onClick={closeModal}>
            {t("common.cancel")}
          </KosmoButton>
          <KosmoButton type="primary" htmlType="submit" isDisabled={isLoading}>
            {type === "create" ? t("common.create") : t("common.update")}
          </KosmoButton>
        </div>
      </Form>
    </ConfigProvider>
  )
}

export default CreateOrUpdateRuleForm
