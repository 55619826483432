import React from "react"
import { DestinationStatuses } from "@/types/orders.types"
import { Tag } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCircleCheck,
  faCirclePlus,
  faCircleXmark,
  faLocationDot,
  faPersonWalkingArrowLoopLeft,
} from "@fortawesome/free-solid-svg-icons"
import { useTranslation } from "react-i18next"

interface DestinationStatusTagProps {
  destinationStatus: DestinationStatuses | undefined
}

const DestinationStatusTag: React.FC<DestinationStatusTagProps> = ({
  destinationStatus = DestinationStatuses.EMPTY,
}) => {
  const { t } = useTranslation()

  switch (destinationStatus) {
    case DestinationStatuses.CANCELED:
      return (
        <Tag
          className="flex items-center m-0 w-fit h-fit"
          color="#fecdd3"
          style={{ borderRadius: "8px" }}
        >
          <span className="flex flex-row">
            <FontAwesomeIcon
              className="flex-shrink-0 h-fit my-auto mr-1 text-rose-600 text-sm"
              icon={faCircleXmark}
            />
            <p className="text-black m-0">{t("common.order-status.cancelled")}</p>
          </span>
        </Tag>
      )
    case DestinationStatuses.TO_DROPOFF:
      return (
        <Tag
          className="flex items-center m-0 w-fit h-fit"
          color="#D8E6FD"
          style={{ borderRadius: "8px" }}
        >
          <span className="flex items-center">
            <FontAwesomeIcon
              className="flex-shrink-0 h-fit my-auto mr-1 text-pink-500 text-sm"
              icon={faLocationDot}
            />
            <p className="text-black m-0">{t("common.order-status.stopToDropoff")}</p>
          </span>
        </Tag>
      )
    case DestinationStatuses.DELIVERED:
      return (
        <Tag
          className="flex items-center m-0 w-fit h-fit"
          color="#bbf7d0"
          style={{ borderRadius: "8px" }}
        >
          <span className="flex flex-row">
            <FontAwesomeIcon
              className="flex-shrink-0 h-fit my-auto mr-1 text-green-500 text-sm"
              icon={faCircleCheck}
            />
            <p className="text-black m-0">{t("common.order-status.delivered")}</p>
          </span>
        </Tag>
      )
    case DestinationStatuses.RETURNING:
      return (
        <Tag
          className="flex items-center h-fit m-0"
          color="#D8E6FD"
          style={{ borderRadius: "8px" }}
        >
          <span className="flex flex-row">
            <FontAwesomeIcon
              className="flex-shrink-0 h-fit my-auto mr-1 text-rose-600 text-sm"
              icon={faPersonWalkingArrowLoopLeft}
            />
            <p className="text-black m-0">{t("common.order-status.returning")}</p>
          </span>
        </Tag>
      )
    case DestinationStatuses.RETURNED:
      return (
        <Tag
          className="flex items-center m-0 w-fit h-fit"
          color="#fbcfe8"
          style={{ borderRadius: "8px" }}
        >
          <span className="flex flex-row">
            <FontAwesomeIcon
              className="flex-shrink-0 h-fit my-auto mr-1 text-green-500 text-sm"
              icon={faCircleCheck}
            />
            <p className="text-black m-0">{t("common.order-status.returned")}</p>
          </span>
        </Tag>
      )
    case DestinationStatuses.CREATED:
    default:
      return (
        <Tag
          className="flex items-center m-0 w-fit h-fit"
          color="#fef3c7"
          style={{ borderRadius: "8px" }}
        >
          <span className="flex flex-row">
            <FontAwesomeIcon
              className="flex-shrink-0 h-fit my-auto mr-1 text-yellow-500 text-sm"
              icon={faCirclePlus}
            />
            <p className="text-black m-0">{t("common.order-status.created")}</p>
          </span>
        </Tag>
      )
    // return <></>
  }
}

export default DestinationStatusTag
