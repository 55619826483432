import { IOrder, DestinationStatuses } from "@/types/orders.types"
import { Progress } from "antd"
import { red, green, orange } from "@ant-design/colors"

const DEFAULT_GREY = "rgba(0, 0, 0, 0.1)"

export const ProgressBar: React.FC<{ order?: IOrder; withStopOrdering?: boolean }> = ({
  order,
  withStopOrdering,
}) => {
  if (!order || !order.recipients?.length || order.recipients?.length === 1) {
    return null
  }

  const numStops = order?.recipients?.length || 0
  const smallGap = numStops > 50 && numStops <= 75 ? "small-gap" : ""
  const zeroGap = numStops > 75 ? "zero-gap" : ""

  return (
    <div className={`orders-progress flex flex-row h-5 space-x-1 ${smallGap} ${zeroGap}`}>
      <Progress
        percent={withStopOrdering ? 100 : calculateProgress(order)}
        steps={numStops}
        strokeColor={calculateStrokeColor(order, withStopOrdering)}
        trailColor="rgba(0,0,0,0.1)"
        showInfo={false}
        size={"small"}
      />
    </div>
  )
}

export const DriverProgressBar: React.FC<{
  total: number
  completed: number
  returned: number
  failed: number
}> = ({ total, completed, returned, failed }) => {
  if (total === 0) {
    return null
  }
  const smallGap = total > 50 && total <= 75 ? "small-gap" : ""
  const zeroGap = total > 75 ? "zero-gap" : ""
  return (
    <div className={`drivers-progress flex flex-row h-5 space-x-1 ${smallGap} ${zeroGap}`}>
      <Progress
        percent={((completed + returned + failed) / total) * 100}
        steps={total}
        size={total > 8 ? "small" : "default"}
        strokeColor={calculateDriverStrokeColor(completed, returned, failed)}
        trailColor="rgba(0,0,0,0.1)"
        showInfo={false}
      />
    </div>
  )
}

const calculateProgress = (order: IOrder): number => {
  const progressStatuses = [
    DestinationStatuses.CANCELED,
    DestinationStatuses.DELIVERED,
    DestinationStatuses.RETURNED,
  ]

  const progress = order.recipients?.reduce((acc, recipient) => {
    if (progressStatuses.includes(recipient.status || DestinationStatuses.EMPTY)) {
      return acc + 1
    }
    return acc
  }, 0)

  if (!progress || !order.recipients) {
    return 0
  }

  return Math.round((progress / order.recipients.length) * 100)
}

const calculateStrokeColor = (order: IOrder, withStopOrdering?: boolean): string[] => {
  const colors: string[] = []

  order.recipients?.forEach((recipient) => {
    if (recipient.status === DestinationStatuses.DELIVERED) {
      colors.push(green.primary!)
    } else if (recipient.status === DestinationStatuses.CANCELED) {
      colors.push(red.primary!)
    } else if (recipient.status === DestinationStatuses.RETURNED) {
      colors.push(orange.primary!)
    } else if (withStopOrdering) {
      colors.push(DEFAULT_GREY)
    }
  })

  return colors
}

const calculateDriverStrokeColor = (
  completed: number,
  returned: number,
  failed: number
): string[] => {
  const colors: string[] = []

  for (let i = 0; i < completed; i++) {
    colors.push(green.primary!)
  }

  for (let i = 0; i < returned; i++) {
    colors.push(orange.primary!)
  }

  for (let i = 0; i < failed; i++) {
    colors.push(red.primary!)
  }

  return colors
}

export default ProgressBar
