export enum IssueTypes {
  NOT_ACCEPTED = "not_accepted",
  PICKUP_DELAYED = "pickup_delayed",
  DELIVERY_DELAYED = "delivery_delayed",
  PICKUP_FAILED = "pickup_failed",
  DELIVERY_FAILED = "delivery_failed",
  DRIVER_REJECTED = "driver_rejected",
  NO_AVAILABLE_DRIVERS = "no_available_drivers",
  ROUTE_DURATION_LIMIT = "route_duration_limit",
  ROUTE_VEHICLE_CAPACITY_LIMIT = "route_vehicle_capacity_limit",
  OTHER = "other",
}