import React from "react"
import { DisplayProvider } from "@/UI"
import { IDisplayProviderProps } from "@/UI/DataDisplay/DisplayProvider/DisplayProvider"

interface ProviderTagProps {
  className?: string
  type?: "provider" | "team"
  name: string
}

const ProviderTag: React.FC<ProviderTagProps & IDisplayProviderProps> = ({
  className,
  type,
  name,
  ...props
}) => {
  return (
    <div
      className={`${className} w-fit bg-[#F3F4F6] py-1 px-2 rounded-lg mr-4 mb-1 mt-1 cursor-pointer`}
    >
      {type === "provider" ? <DisplayProvider fontStyle="font-normal" {...props} /> : <p>{name}</p>}
    </div>
  )
}

export default ProviderTag
