import React, { memo, useState } from "react"
import { Marker } from "@react-google-maps/api"
import { faLocationDot } from "@fortawesome/free-solid-svg-icons"
import { TaskPin } from "@/types/map"
import UnassignedInfoWindow from "./UnassignedInfoWindow"

interface UnassignedTaskMarkerProps {
  task: TaskPin
  clusterer?: any // Clusterer Type not defined in package
  openInfoWindowRef?: React.MutableRefObject<null | (() => void)>
}

const UnassignedTaskMarker: React.FC<UnassignedTaskMarkerProps> = ({
  task,
  clusterer,
  openInfoWindowRef,
}) => {
  const [showInfoWindow, setShowInfoWindow] = useState(false)

  if (!task.coordinates || !task.coordinates.latitude || !task.coordinates?.longitude) {
    return <></>
  }

  const handleMarkerClick = () => {
    if (openInfoWindowRef) {
      if (openInfoWindowRef.current) {
        openInfoWindowRef.current()
      }
      openInfoWindowRef.current = () => setShowInfoWindow(false)
    }
    if (task.onPinClickOpen) {
      task.onPinClickOpen()
    }
    setShowInfoWindow(true)
  }

  const handleInfoWindowClose = () => {
    setShowInfoWindow(false)
    if (task.onPinClickClose) {
      task.onPinClickClose()
    }
    if (openInfoWindowRef?.current) {
      openInfoWindowRef.current = null
    }
  }

  return (
    <>
      <Marker
        title={task.orderID}
        position={{
          lat: task.coordinates.latitude,
          lng: task.coordinates.longitude,
        }}
        {...(clusterer ? { clusterer: { ...clusterer } } : {})}
        onClick={handleMarkerClick}
        icon={{
          path: faLocationDot.icon[4] as string,
          fillColor: "#00000",
          fillOpacity: 1,
          anchor: new google.maps.Point(
            faLocationDot.icon[0] / 2, // width
            faLocationDot.icon[1] // height
          ),
          strokeWeight: 1,
          strokeColor: "#ffffff",
          scale: 0.05,
        }}
        zIndex={5}
      >
        <UnassignedInfoWindow
          tasks={[task]}
          showInfoWindow={showInfoWindow}
          handleInfoWindowClose={handleInfoWindowClose}
          position={{ lat: task.coordinates.latitude, lng: task.coordinates.longitude }}
        />
      </Marker>
    </>
  )
}

export default memo(UnassignedTaskMarker)
