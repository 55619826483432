import React from "react"
import { Driver } from "@/types/drivers.types"
import { useTranslation } from "react-i18next"
import { DriverProgressBar } from "@/modules/shared/ProgressBar/ProgressBar"
import { Loader } from "@/UI"
import { DisplayDriverName } from "@/modules/shared/DriverDisplay/DisplayDriverName"
import { WeightCapacityCellSimple } from "@/modules/new-orders/DraftOrderTable/cells/WeightCapacityCell"

interface DriverTreeTitleProps {
  driver: Driver
  total: number
  completed: number
  returned: number
  failed: number
  routesCount: number
  totalWeight: number
  showProgress?: boolean
  isNodeLoading?: boolean
}

export const DriverTreeTitle: React.FC<DriverTreeTitleProps> = ({
  driver,
  total,
  completed,
  returned,
  failed,
  totalWeight,
  showProgress,
  routesCount,
  isNodeLoading,
}) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col" id={driver.id}>
      <div className="flex gap-x-2">
        <DisplayDriverName driver={driver} />
        {isNodeLoading && <Loader fontSize={12} />}
      </div>
      <div className="flex gap-x-2">
        <p className="text-sm text-gray-500 font-normal">
          {routesCount || 0} {t("common.routes")} | {total || 0} {t("common.stops")} |{" "}
        </p>
        <WeightCapacityCellSimple
          totalWeight={totalWeight}
          weightCapacity={driver.weightCapacity || 1000}
          className="text-sm"
        />
      </div>
      {showProgress && (
        <DriverProgressBar
          total={total}
          completed={completed}
          returned={returned}
          failed={failed}
        />
      )}
    </div>
  )
}

interface BlastTreeTitleProps {
  total: number
  routesCount: number
  isNodeLoading?: boolean
}

export const BlastTreeTitle: React.FC<BlastTreeTitleProps> = ({
  total,
  isNodeLoading,
  routesCount,
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col">
      <div className="flex gap-x-2">
        <div>{t("common.autoassign")}</div>
        {isNodeLoading && <Loader fontSize={12} />}
      </div>
      <p className="text-sm text-gray-500 font-normal">
        {routesCount || 0} {t("common.routes")} | {total || 0} {t("common.stops")}
      </p>
    </div>
  )
}

interface UnassignedTreeTitleProps {
  total: number
}

export const UnassignedTreeTitle: React.FC<UnassignedTreeTitleProps> = ({ total }) => {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col">
      <div className="flex gap-x-2">{t("common.unassigned")}</div>
      <p className="text-sm text-gray-500 font-normal">
        {total || 0} {t("common.stops").toLowerCase()}
      </p>
    </div>
  )
}
