import axios from "@/modules/shared/AxiosInterceptor"
import { CreateOrderPayload } from "@/types/createSingleOrder.types"
import { AssignmentTypes } from "@/types/draftOrder.types"
import { IssueTypes } from "@/types/issues.types"
import { DispatchRoute, StopDescription } from "@/types/orders.types"
import { DestinationStatuses, GlobalOrdersMetadata, IOrder, Location } from "@/types/orders.types"
import { OrderStatusesTypes } from "@/types/statuses.types"
import { TaskFailureReason } from "@/types/taskFailureReasons.types"
import { AxiosError, AxiosResponse } from "axios"
import { isEmpty } from "lodash"

export interface IGetDeliveriesResponse {
  deliveries: IOrder[]
  metadata: GlobalOrdersMetadata
}

export interface GetDeliveriesQueryParams {
  limit?: number
  offset?: number
  status?: OrderStatusesTypes | null
  failureReasons?: TaskFailureReason[]
  issue?: IssueTypes | null
  to?: number
  from?: number
  is_active?: boolean
  provider?: string
  order_id?: string
  provider_order_id?: string
  q?: string
  type?: string
  zoneIds?: string[]
  teams?: number[]
  driversIds?: string[]
  couriers?: string[]
  csvForDriverPay?: boolean
  dateFilterBy?: string
  locations?: string[]
}

export const buildDeliveriesQueryString = (
  queryStringParams: GetDeliveriesQueryParams,
  isStops = false
) => {
  if (isEmpty(queryStringParams)) {
    return ""
  }

  const limit = queryStringParams.limit !== undefined ? `&limit=${queryStringParams.limit}` : ""

  const offset = queryStringParams.offset !== undefined ? `&offset=${queryStringParams.offset}` : ""

  const status =
    queryStringParams.status !== undefined && !isEmpty(queryStringParams.status)
      ? `&status=${queryStringParams.status}`
      : ""

  // queryStringParams.failureReasons = [TaskFailureReason.DELIVER_ANOTHER_TIME]
  const failure_reason =
    isStops &&
    queryStringParams.failureReasons !== undefined &&
    !isEmpty(queryStringParams.failureReasons)
      ? `&failure_reason=${queryStringParams.failureReasons}`
      : ""

  const issue =
    queryStringParams.issue !== undefined && !isEmpty(queryStringParams.issue)
      ? `&issue=${queryStringParams.issue}`
      : ""

  const to = queryStringParams.to !== undefined ? `&to=${queryStringParams.to}` : ""

  const from = queryStringParams.from !== undefined ? `&from=${queryStringParams.from}` : ""
  const is_active =
    queryStringParams.is_active !== undefined ? `&is_active=${queryStringParams.is_active}` : ""

  const provider =
    queryStringParams.provider !== undefined ? `&provider=${queryStringParams.provider}` : ""

  const order_id =
    queryStringParams.order_id !== undefined ? `&order_id=${queryStringParams.order_id}` : ""

  const provider_order_id =
    queryStringParams.provider_order_id !== undefined
      ? `&provider_order_id=${queryStringParams.provider_order_id}`
      : ""

  const type = queryStringParams.type !== undefined ? `&type=${queryStringParams.type}` : ""

  const zoneIds =
    queryStringParams.zoneIds !== undefined ? `&zoneIds=${queryStringParams.zoneIds}` : ""

  const teams = queryStringParams.teams !== undefined ? `&teams=${queryStringParams.teams}` : ""
  const driversIds =
    queryStringParams.driversIds !== undefined ? `&driversIds=${queryStringParams.driversIds}` : ""
  const couriers =
    queryStringParams.couriers !== undefined ? `&couriers=${queryStringParams.couriers}` : ""

  const q =
    queryStringParams.q !== undefined && queryStringParams.q
      ? `&q=${encodeURIComponent(queryStringParams.q)}`
      : ""

  const csvForDriverPay =
    queryStringParams.csvForDriverPay !== undefined
      ? `&csv_for_driver_pay=${queryStringParams.csvForDriverPay}`
      : ""
  const dateFilterBy =
    queryStringParams.dateFilterBy !== undefined
      ? `&dateFilterBy=${queryStringParams.dateFilterBy}`
      : ""
  const locations =
    queryStringParams.locations !== undefined ? `&locations=${queryStringParams.locations}` : ""

  return `${limit}${offset}${status}${failure_reason}${issue}${to}${from}${is_active}${provider}${order_id}${provider_order_id}${q}${type}${zoneIds}${couriers}${teams}${driversIds}${csvForDriverPay}${dateFilterBy}${locations}`
}

export function getDeliveries(
  queryStringParams: GetDeliveriesQueryParams = {},
  signal?: AbortSignal
): Promise<AxiosResponse<IGetDeliveriesResponse>> {
  const baseUrl = `/deliveries`
  const queryString = buildDeliveriesQueryString(queryStringParams)
  return axios
    .get(`${baseUrl}?${queryString}`, {
      signal,
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function exportRouteSummary(
  queryStringParams: GetDeliveriesQueryParams = {}
): Promise<AxiosResponse<Blob>> {
  const baseUrl = `/deliveries/export/route-summary`
  const queryString = buildDeliveriesQueryString(queryStringParams)
  return axios
    .get(`${baseUrl}?${queryString}`, {
      responseType: "blob",
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function exportOrderSummary(
  queryStringParams: GetDeliveriesQueryParams = {}
): Promise<AxiosResponse<Blob>> {
  const baseUrl = `/deliveries/export/order-summary`
  const queryString = buildDeliveriesQueryString(queryStringParams)
  return axios
    .get(`${baseUrl}?${queryString}`, {
      responseType: "blob",
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function exportStopSummary(
  queryStringParams: GetDeliveriesQueryParams = {}
): Promise<AxiosResponse<Blob>> {
  const baseUrl = `/deliveries/export/stop-summary`
  const queryString = buildDeliveriesQueryString(queryStringParams, true)
  return axios
    .get(`${baseUrl}?${queryString}`, {
      responseType: "blob",
    })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export function postDelivery(quoteId: string): Promise<AxiosResponse<IOrder>> {
  return axios
    .post("/deliveries", { quoteId })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

type GetOrderPayload = Omit<IOrder, "routeDirections"> & { routeDirections?: string }

export function getDelivery(
  id: string,
  withRoute?: boolean
): Promise<AxiosResponse<GetOrderPayload>> {
  return axios
    .get(`/deliveries/${id}${withRoute ? "?withRoute=true" : ""}`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export const cancelDelivery = (id: string): Promise<AxiosResponse<any>> => {
  return axios
    .post(`/deliveries/cancel/${id}`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export const cancelDeliveries = (orderIds: string[]): Promise<AxiosResponse<any>> => {
  return axios
    .delete(`/deliveries`, { data: { orderIds } })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export const postSingleOrder = (order: CreateOrderPayload): Promise<AxiosResponse<any>> => {
  return axios
    .post(`/v1/deliveries`, { ...order })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export const getDeliveryNextStatuses = (
  id: string
): Promise<
  AxiosResponse<{ statuses: OrderStatusesTypes[]; stops: Record<number, DestinationStatuses[]> }>
> => {
  return axios
    .get(`deliveries/${id}/statuses`)
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export const putDeliveryNextStatuses = (
  id: string,
  status: OrderStatusesTypes
): Promise<AxiosResponse<IOrder>> => {
  return axios
    .put(`deliveries/${id}/statuses`, {
      status,
    })
    .then((response) => response)
    .catch((error: AxiosError) => {
      throw error
    })
}

export const putDeliveryStopNextStatuses = (
  id: string,
  status: DestinationStatuses,
  index: number
): Promise<AxiosResponse<IOrder>> => {
  return axios
    .put(`deliveries/${id}/statuses/${index}`, {
      status,
    })
    .then((response) => response)
    .catch((error: AxiosError) => {
      throw error
    })
}

export const updateDeliveryArrivalStatus = (id: string): Promise<AxiosResponse<IOrder>> => {
  return axios
    .put(`deliveries/statuses/arrival`, {
      id,
    })
    .then((response) => response)
    .catch((error: AxiosError) => {
      throw error
    })
}

export const putDeliveryTip = (
  deliveryId: string,
  amount: number
): Promise<AxiosResponse<IOrder>> => {
  return axios
    .put(`deliveries/${deliveryId}/tips`, {
      amount,
    })
    .then((response) => response)
    .catch((error: AxiosError) => {
      throw error
    })
}

export const putDeliveryBarcode = (
  deliveryId: string,
  trackingHash: string
): Promise<AxiosResponse<IOrder>> => {
  return axios
    .put(`deliveries/${deliveryId}/barcode`, {
      trackingHash,
    })
    .then((response) => response)
    .catch((error: AxiosError) => {
      throw error
    })
}

export const putDeliveryBarcodes = (deliveryIds: string[]): Promise<AxiosResponse<IOrder[]>> => {
  return axios
    .put(`deliveries/barcodes`, {
      deliveryIds,
    })
    .then((response) => response)
    .catch((error: AxiosError) => {
      throw error
    })
}

interface TriggerDeliveryReassignPayload {
  orderId: string
  type: string
  ruleId?: string
  teamId?: number
  driverId?: string
  assignmentType?: AssignmentTypes
  blastAssignmentLimit?: number
}

type BulkTriggerDeliveryReassignPayload = Omit<TriggerDeliveryReassignPayload, "orderId"> & {
  orderIds: string[]
}

export const triggerDeliveryReassign = async ({
  orderId,
  ruleId,
  teamId,
  type,
  assignmentType,
  blastAssignmentLimit,
}: TriggerDeliveryReassignPayload): Promise<AxiosResponse<IOrder>> => {
  try {
    return axios.post(`deliveries/${orderId}/reassign`, {
      ...(ruleId ? { ruleId } : {}),
      ...(teamId ? { teamId } : {}),
      ...(assignmentType ? { assignmentType } : {}),
      ...(type ? { type } : {}),
      ...(blastAssignmentLimit ? { blastAssignmentLimit } : {}),
    })
  } catch (error) {
    throw error
  }
}

export const triggerBulkDeliveryReassign = async ({
  orderIds,
  ruleId,
  teamId,
  driverId,
  type,
  assignmentType,
  blastAssignmentLimit,
}: BulkTriggerDeliveryReassignPayload): Promise<AxiosResponse<IOrder[]>> => {
  try {
    return axios.post(`deliveries/bulk-reassign`, {
      orderIds,
      ...(ruleId ? { ruleId } : {}),
      ...(teamId ? { teamId } : {}),
      ...(driverId ? { driverId } : {}),
      ...(assignmentType ? { assignmentType } : {}),
      ...(type ? { type } : {}),
      ...(blastAssignmentLimit ? { blastAssignmentLimit } : {}),
    })
  } catch (error) {
    throw error
  }
}

export const notifyDelayedDelivery = async (
  deliveryId: string,
  stopId: number,
  transport: string
): Promise<AxiosResponse<IOrder>> => {
  try {
    return axios.post(`deliveries/${deliveryId}/notify-delay`, {
      transport,
      stopId,
    })
  } catch (error) {
    throw error
  }
}

export const putDeliveryRecipients = async (
  deliveryId: string,
  recipients: Location[],
  arrivalETA?: number
): Promise<AxiosResponse<IOrder>> => {
  try {
    return axios.put(`deliveries/${deliveryId}`, {
      recipients,
      arrivalETA,
    })
  } catch (error) {
    throw error
  }
}

export const duplicateDelivery = async (
  deliveryId: string,
  index: number
): Promise<AxiosResponse<IOrder>> => {
  try {
    return axios.post(`deliveries/${deliveryId}/duplicate`, { stopIndex: index })
  } catch (error) {
    throw error
  }
}

export const putDeliveryRoute = async (
  deliveryId: string,
  routeDirections: string
): Promise<AxiosResponse<string>> => {
  try {
    return axios.put(`deliveries/${deliveryId}/route`, { routeDirections })
  } catch (error) {
    throw error
  }
}

export const deletePOD = async (podURL: string): Promise<AxiosResponse<void>> => {
  try {
    return axios.delete(`deliveries/pod?podURL=${podURL}`)
  } catch (error) {
    throw error
  }
}

export const putUpdateDeliveryStop = async (
  deliveryId: string,
  recipient: Location,
  stopIndex: number
): Promise<AxiosResponse<Location>> => {
  try {
    return axios.put(`deliveries/${deliveryId}/stop`, { recipient, stopIndex })
  } catch (error) {
    throw error
  }
}

export const postMoveActiveStop = async (
  deliveryId: string,
  targetOrderId: string,
  stop: StopDescription
): Promise<AxiosResponse<IOrder>> => {
  try {
    return axios.put(`deliveries/${deliveryId}/move-stop`, { targetOrderId, stop })
  } catch (error) {
    throw error
  }
}

export const postMoveOrderToRoute = async (
  deliveryId: string,
  targetOrderId: string
): Promise<AxiosResponse<IOrder>> => {
  try {
    return axios.put(`deliveries/${deliveryId}/move-route`, { targetOrderId })
  } catch (error) {
    throw error
  }
}

export async function getDeliveriesAsRoutes(
  params: GetDeliveriesQueryParams,
  signal?: AbortSignal
) {
  const baseUrl = `deliveries/routes`
  const queryString = buildDeliveriesQueryString(params)
  const url = `${baseUrl}?${queryString}`

  return axios
    .get<DispatchRoute[]>(url, { signal })
    .then((response) => response)
    .catch((error) => {
      throw error
    })
}

export const postMoveSingleRoute = async (
  deliveryId: string,
  targetOrderId: string
): Promise<AxiosResponse<IOrder>> => {
  try {
    return axios.put(`deliveries/${deliveryId}/move-route`, { targetOrderId })
  } catch (error) {
    throw error
  }
}
