import React from "react"
import { Form, Radio } from "antd"
import { SizeType } from "antd/lib/config-provider/SizeContext"
import Image from "next/image"
import { vehicleImage } from "@/utils/providers"
import { VehicleType } from "@/types/vehicle.types"
import { VEHICLES } from "@/modules/profile/DriversSettings/utils"

interface IVehicleTypeFormItem {
  label: string
  name?: string
  size?: SizeType
  disabled?: boolean
  selectedVehicleType: VehicleType | null
  setSelectedVehicleType: (value: VehicleType) => void
  onRadioClick: (value: VehicleType) => void
  className?: string
}

export const VehicleTypeFormItem: React.FC<IVehicleTypeFormItem> = ({
  label,
  name,
  size = "middle",
  disabled = false,
  selectedVehicleType,
  setSelectedVehicleType,
  onRadioClick,
  className,
}) => {
  let vehicleSize = 28
  switch (size) {
    case "small":
      vehicleSize = 20
      break
    case "middle":
      vehicleSize = 24
      break
    case "large":
      vehicleSize = 26
      break
    default:
      break
  }

  return (
    <Form.Item label={label} className={className} name={name}>
      <Radio.Group
        className="flex"
        value={selectedVehicleType}
        onChange={(e) => setSelectedVehicleType(e.target.value)}
        disabled={disabled}
        size={size}
      >
        {VEHICLES.map((value) => (
          <Radio.Button
            key={value}
            value={value}
            className="flex-1 px-2 py-2"
            onClick={() => onRadioClick(value)}
          >
            <Image
              src={vehicleImage(value)}
              priority={true}
              width={vehicleSize}
              height={vehicleSize}
            />
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  )
}
