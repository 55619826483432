import React from "react"
import { Progress } from "antd"
import { useDrivers } from "@/hooks/useDrivers"
import { Location } from "@/types/orders.types"

interface NoCapacityCellProps {
  totalWeight: number
  className?: string
}

interface WeightCapacityCellSimpleProps {
  totalWeight: number
  weightCapacity: number
  className?: string
}

interface CapacityCellProps {
  totalWeight: number
  weightCapacity: number
  className?: string
}

interface WeightCapacityCellProps {
  recipients: Location[]
  driverId?: string
  className?: string
}

const NoCapacityCell: React.FC<NoCapacityCellProps> = ({ totalWeight, className }) => {
  return (
    <p className={`text-gray-400 whitespace-nowrap text-ellipsis overflow-hidden ${className}`}>
      {totalWeight} kg
    </p>
  )
}

export const WeightCapacityCellSimple: React.FC<WeightCapacityCellSimpleProps> = ({
  totalWeight,
  weightCapacity,
  className,
}) => {
  if (weightCapacity === 0) {
    return <NoCapacityCell totalWeight={totalWeight} className={className} />
  }

  return (
    <CapacityCell totalWeight={totalWeight} weightCapacity={weightCapacity} className={className} />
  )
}

const CapacityCell: React.FC<CapacityCellProps> = ({ totalWeight, weightCapacity, className }) => {
  return (
    <div className="flex items-center gap-x-1">
      <Progress
        type="circle"
        strokeColor={
          weightCapacity === totalWeight
            ? "success"
            : weightCapacity > totalWeight
            ? "#1890ff"
            : "#ff4d4f"
        }
        percent={(totalWeight / weightCapacity) * 100}
        strokeWidth={20}
        size={14}
        format={() => {
          return `${((totalWeight / weightCapacity) * 100).toFixed(2)}%`
        }}
      />
      <p className={`text-gray-400 whitespace-nowrap text-ellipsis overflow-hidden ${className}`}>
        {totalWeight} kg
      </p>
    </div>
  )
}

const WeightCapacityCell: React.FC<WeightCapacityCellProps> = ({
  recipients,
  driverId,
  className,
}) => {
  const { drivers } = useDrivers()

  const totalWeight = recipients?.reduce((acc, recipient) => {
    return acc + (recipient?.parcel?.dimensions?.weight || 0)
  }, 0)

  const driver = drivers.find((driver) => driver.id === driverId)

  if (driver?.weightCapacity) {
    return (
      <CapacityCell
        totalWeight={totalWeight}
        weightCapacity={driver.weightCapacity}
        className={className}
      />
    )
  }

  return <NoCapacityCell totalWeight={totalWeight} className={className} />
}

export default WeightCapacityCell
