import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { getDisplayAddress } from "@/utils/addresses"
import {
  faCircleDot,
  faPen,
  faLocationDot,
  faCheck,
  faX,
  faFlag,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Card, Button, Typography, Tooltip } from "antd"
import { last } from "lodash"
import { SetterOrUpdater, useRecoilValue, useSetRecoilState } from "recoil"
import {
  formatToPickup,
  formatMultipleDropoffs,
  formatShortLocationToLocation,
  formatShortenedSenderToLocation,
  formatShortenedArrivalToArrival,
} from "@/utils/stopsFormatter"
import { MapOrder, RoutesDirections } from "@/types/map"
import { Location, StopType, OrderType } from "@/types/orders.types"
import { ServerDeliveryType } from "@/types/deliveryTypes.types"
import { DrawerOrder, dispatchPageModalsAtom } from "@/atoms/dispatchPageModalsAtom"
import { GenerateNewRouteFunc } from "@/hooks/useCalculateRoute"
import { isOrderStatusEditable } from "@/utils/status"
import { ProvidersType } from "@/types/providers.types"
import MapStopsList from "./MapStopsList"
import { getRecipientDisplayedTime, getMapOrderArrivalTime } from "@/utils/order-time-calculations"
import OrderStatusTag from "@/modules/shared/Statuses/OrderStatusTag"
import AssignOrderSelect from "@/modules/shared/AssignOrderSelect/AssignOrderSelect"
import { IDispatchRule } from "@/types/dispatchRules.types"
import { AssignmentTypes, DraftOrderStatus } from "@/types/draftOrder.types"
import { Driver } from "@/types/drivers.types"
import { EnrichedTeam } from "@/types/teams.types"
import { isOrderReassignable } from "@/utils/delivery.utils"
import { useTranslation } from "react-i18next"
import { is3PLsEnabledSelector } from "@/atoms/userAtom"
import StopTypeBadge from "@/UI/DataDisplay/StopTypeBadge"
import { Loader } from "@/UI"

export enum OrderAssignedTo {
  DRIVER = "driver",
  TEAM = "team",
  THIRD_PARTY = "3pl",
  NONE = "",
}

interface MapOrderCardProps {
  order: MapOrder
  assignedDriver?: Driver
  teams: EnrichedTeam[]
  drivers: Driver[]
  dispatchRules: IDispatchRule[]
  handleSelect: (reassignTarget: string) => void
  handleAssignmentSelect: (teamId: number, assignmentType: AssignmentTypes) => Promise<void> | void
  openedOrders: string[]
  generateNewRoute: GenerateNewRouteFunc
  removeRoute: (id: string, cleanCache?: boolean) => void
  selectedRowsKeys: string[]
  setOpenedOrders: SetterOrUpdater<string[]>
  setSelectedRowsKeys: Dispatch<SetStateAction<string[]>>
  assignedTo: OrderAssignedTo
  draftOrderConfirmInProgress: boolean
  handleConfirmDraftOrder: () => Promise<void>
}

const { Text } = Typography

const IconContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="flex items-center flex-shrink-0 justify-center w-5 h-5 bg-kosmoLightGray rounded-md">
      {children}
    </div>
  )
}

const MapOrderCard: React.FC<MapOrderCardProps> = ({
  order,
  teams,
  drivers,
  dispatchRules,
  handleSelect,
  handleAssignmentSelect,
  openedOrders,
  selectedRowsKeys,
  removeRoute,
  setSelectedRowsKeys,
  setOpenedOrders,
  generateNewRoute,
  assignedTo,
  draftOrderConfirmInProgress,
  handleConfirmDraftOrder,
  assignedDriver,
}) => {
  const { t } = useTranslation()

  const is3PlsEnabled = useRecoilValue(is3PLsEnabledSelector)
  const setDispatchPageModals = useSetRecoilState(dispatchPageModalsAtom)

  const dropoffs = order?.tt?.map((task) => formatShortLocationToLocation(task?.r))
  const sender = formatShortenedSenderToLocation(order?.sn)

  const lastRecipient = formatShortLocationToLocation(last(order?.tt)?.r!)
  const formattedDropoffs = formatMultipleDropoffs(dropoffs, ServerDeliveryType.INSTANT, true)
  const formattedPickup = formatToPickup(sender, ServerDeliveryType.INSTANT)
  const isOpen = openedOrders.includes(order?.id)
  const active = selectedRowsKeys.includes(order?.id)

  const parsedDirections: RoutesDirections = JSON.parse(order.rd || "{}")
  // We need a local state as route directions will be refreshed on next polling
  const [localDirections, setLocalDirections] = useState<RoutesDirections>(parsedDirections)
  const recipients = order?.tt?.reduce((previousValue, currentValue) => {
    return [...previousValue, formatShortLocationToLocation(currentValue?.r)]
  }, [] as Location[])

  const color = localDirections?.color

  // Handle showing not showing the route
  useEffect(() => {
    if (active) {
      const destinationPoints = formattedDropoffs.slice()

      if (order.arr !== undefined) {
        destinationPoints.push({
          name: order.arr.c.n ?? "",
          phone: order.arr.c.p ?? "",
          latitude: order.arr.l.lat ?? 0,
          longitude: order.arr.l.lon ?? 0,
          address: order.arr.l.a ?? "",
          type: StopType.ARRIVAL,
        })
      }
      const newStopsOrder = [
        [sender?.location?.latitude || 0, sender?.location?.longitude || 0],
        ...destinationPoints?.map((destination) => [
          destination?.latitude || 0,
          destination?.longitude || 0,
        ]),
      ]

      generateNewRoute(
        order.id,
        formattedPickup,
        destinationPoints,
        {
          ...localDirections,
          stops: localDirections?.stops,
          type: order.t,
          newStopsOrder,
          // color: localDirections?.color,
        },
        order,
        localDirections?.stops?.length === newStopsOrder?.length
      ).then((routeDirections) => {
        if (routeDirections) {
          setLocalDirections({
            color: routeDirections?.color,
            stops: routeDirections?.stops || [],
            routes: {
              encodedPolylines: routeDirections?.encodedPolylines,
              provider: routeDirections.provider,
            },
          })
        }
      })
    } else {
      removeRoute(order.id)
    }
  }, [selectedRowsKeys, formattedDropoffs?.length])

  const isReassignDisabled =
    order.t === OrderType.ACTIVE ? !isOrderReassignable(order.s, order.p, order.dt) : true

  const onClick = active
    ? () => {
        setSelectedRowsKeys((state) => state.filter((id) => id !== order?.id))
      }
    : () => {
        setSelectedRowsKeys((state) => [...state, order.id])
      }

  // const currentRoute = multipleRoutes?.find((route) => route.id?.startsWith(`${order.id}-`))

  const handleClickOpenList = isOpen
    ? () => setOpenedOrders((state) => state.filter((orderId) => orderId !== order?.id))
    : () => setOpenedOrders((state) => [...state, order?.id])

  const lastRecipientEta = getRecipientDisplayedTime(order.t, lastRecipient, t)

  const canAssign = is3PlsEnabled || drivers.length > 0 || teams.length > 0

  const isEditionDisabled =
    order.t === OrderType.ACTIVE &&
    (order.p !== ProvidersType.OWN_FLEET || !isOrderStatusEditable(order.s))

  const canConfirm = order.t === OrderType.DRAFT && assignedTo !== OrderAssignedTo.NONE

  const confirmLoading =
    order.t === OrderType.DRAFT
      ? order.s === DraftOrderStatus.ORDERING || order.s === DraftOrderStatus.QUOTING
      : true
  const confirmFailed =
    canConfirm &&
    (order.s === DraftOrderStatus.ORDERING_FAILED || order.s === DraftOrderStatus.QUOTING_FAILED)

  const endContact = order.arr?.c.n || t("common.endLocation")
  const endAddress = order.arr?.l.a
  const arrivalTime = getMapOrderArrivalTime(order)

  return (
    <Card
      onClick={onClick}
      style={{
        width: "100%",
        ...(active ? { borderColor: color } : {}),
      }}
      styles={{ body: { padding: "10px 10px", width: "100%" } }}
      className="shadow-sm px-1"
      key={order.id}
      id={order.id}
    >
      <div className="w-full items-start">
        <div className="flex flex-col gap-y-2 w-full">
          <div className="grid grid-cols-12 gap-x-2 items-center">
            <div className="flex col-span-9 gap-x-2">
              <IconContainer>
                <FontAwesomeIcon
                  className="flex-shrink-0 h-3 mx-auto my-auto text-black text-sm"
                  icon={faCircleDot}
                />
              </IconContainer>
              <Text className="flex flex-shrink-0 font-medium text-black capitalize" ellipsis>
                {sender?.location?.alias
                  ? sender?.location?.alias
                  : `${sender?.firstName} ${sender?.lastName}`}
              </Text>
            </div>
            <div className="flex col-span-3 justify-end" onClick={(e) => e.stopPropagation()}>
              <div className="flex gap-x-2 items-center">
                {canAssign && (
                  <AssignOrderSelect
                    compact
                    teams={teams as any}
                    drivers={drivers}
                    handleAssignmentSelect={handleAssignmentSelect}
                    handleSelectDriverOrTeam={handleSelect}
                    dispatchRules={dispatchRules}
                    dropdownValue={assignedDriver?.name}
                    placement="bottomLeft"
                    listHeight={300}
                    disabledText={
                      order.t === OrderType.ACTIVE && isReassignDisabled
                        ? t("map.MapOrderCard.assignDisabled")
                        : undefined
                    }
                    disableBlastLimitModal={true}
                  />
                )}
                <Tooltip title={t("common.edit")} className="rounded-full">
                  <Button
                    disabled={isEditionDisabled}
                    onClick={() => {
                      const newOrder = {
                        ...order,
                        id: order?.id,
                        orderID: order?.oid,
                        recipients: recipients,
                        type: order.t,
                        ...(order.t === OrderType.ACTIVE
                          ? { priceBreakdown: { distance: order.m.td } }
                          : { metadata: { totalDistance: order.m.td } }),
                        arrival: formatShortenedArrivalToArrival(order.arr),
                      } as unknown as DrawerOrder
                      setDispatchPageModals((currentState) => ({
                        ...currentState,
                        editOrderDrawerInstance: { ...newOrder, sender: sender },
                      }))
                      if (!active) {
                        onClick()
                      }
                    }}
                    shape="circle"
                    size="small"
                    className="flex items-center justify-center bg-gray-100"
                  >
                    <FontAwesomeIcon
                      className="text-gray-400"
                      height={10}
                      width={10}
                      icon={faPen}
                    />
                  </Button>
                </Tooltip>
                {canConfirm && (
                  <Tooltip
                    title={
                      !confirmFailed
                        ? t("common.sendToDrivers")
                        : t("map.MapOrderCard.confirmFailed")
                    }
                    className="rounded-full"
                  >
                    {confirmLoading || draftOrderConfirmInProgress ? (
                      <div className="flex items-center justify-center bg-gray-100 w-[40px] h-[25px]">
                        <Loader size="small" fontSize={10} color="black" />
                      </div>
                    ) : !confirmFailed ? (
                      <Button
                        onClick={handleConfirmDraftOrder}
                        shape="circle"
                        size="small"
                        type="primary"
                        className="flex items-center justify-center"
                        icon={
                          <FontAwesomeIcon
                            className="text-white"
                            height={10}
                            width={10}
                            icon={faCheck}
                          />
                        }
                      />
                    ) : (
                      <Button
                        shape="circle"
                        size="small"
                        className="flex cursor-default items-center justify-center bg-red-100 hover:bg-red-100 focus:bg-red-100 hover:border-red-100"
                        icon={
                          <FontAwesomeIcon
                            className="text-red-400"
                            icon={faX}
                            height={8}
                            width={8}
                          />
                        }
                      />
                    )}
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
          <div className="flex w-full gap-x-2">
            {order?.tt?.length === 1 ? (
              <>
                <IconContainer>
                  <FontAwesomeIcon
                    className="flex flex-shrink-0 h-3 text-black text-sm"
                    icon={faLocationDot}
                  />
                </IconContainer>
                <div className="flex flex-col w-full">
                  <div className="flex flex-row">
                    <div className="flex gap-x-2 w-8/12">
                      <Text className="font-medium text-black capitalize">
                        {lastRecipient?.firstName} {lastRecipient?.lastName}
                      </Text>
                      {lastRecipient?.type === StopType.PICKUP && <StopTypeBadge />}
                    </div>
                    {order.t === OrderType.ACTIVE && (
                      <div className="flex w-4/12 justify-end text-end">
                        <OrderStatusTag status={order.s} />
                      </div>
                    )}
                  </div>
                  <div className="grid grid-cols-12 gap-x-2">
                    <div className="flex col-span-9">
                      <p
                        className="text-gray-500 whitespace-nowrap text-ellipsis overflow-hidden"
                        style={{ fontSize: 13 }}
                      >
                        {getDisplayAddress(lastRecipient?.location, true)}
                      </p>
                    </div>
                    {!!lastRecipientEta && (
                      <div className="flex col-span-3 justify-end text-end items-center">
                        <p className="text-sm text-gray-500 text-end">{lastRecipientEta}</p>
                      </div>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <MapStopsList
                orderType={order.t}
                tasks={order?.tt}
                onOpen={handleClickOpenList}
                isOpen={isOpen}
                orderStatus={order.t === OrderType.ACTIVE ? order.s : undefined}
              />
            )}
          </div>
          {!!endAddress && (
            <div className="flex w-full gap-x-2">
              <IconContainer>
                <FontAwesomeIcon
                  className="flex flex-shrink-0 h-3 text-black text-sm"
                  icon={faFlag}
                />
              </IconContainer>
              <div className="flex w-full items-center justify-between">
                <Text className="flex flex-shrink-0 font-medium text-black capitalize" ellipsis>
                  {endContact}
                </Text>
                {!!arrivalTime && (
                  <div className="flex col-span-3 justify-end text-end items-center">
                    <p className="text-sm text-gray-500 text-end">{arrivalTime}</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

export default MapOrderCard
