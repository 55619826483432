import React from "react"

import { ProvidersType } from "@/types/providers.types"
import { Reorder } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars } from "@fortawesome/free-solid-svg-icons"
import ProviderRankCard from "../../ProvidersList/components/ProviderRankCard"
import { IProviderDetails } from "@/types/organization-providers"

interface TeamsCouriersRankListProps {
  providers: (ProvidersType | string)[]
  allCouriersList: (IProviderDetails & { active: boolean })[]
  setSelectedProviders: (providers: (ProvidersType | string)[]) => void
}

const TeamsCouriersRankList: React.FC<TeamsCouriersRankListProps> = ({
  providers,
  allCouriersList,
  setSelectedProviders,
}) => {
  return (
    <Reorder.Group
      axis="y"
      values={providers}
      onReorder={(newOrder) => {
        setSelectedProviders(newOrder)
      }}
      layoutScroll
      style={{ overflowY: "scroll", maxHeight: 400 }}
      className="space-y-2 flex flex-col w-full border rounded-lg p-4"
      as="ul"
    >
      {providers?.map((provider, index) => {
        const isProvider = allCouriersList?.find((courier) => courier.name === provider)
        return (
          <Reorder.Item key={provider} value={provider} className={`relative`} drag="y">
            <div className="group flex w-full rounded-lg border px-4 py-3 justify-between items-center select-none cursor-grab">
              <ProviderRankCard
                type={!!isProvider ? "provider" : "team"}
                rank={index + 1}
                provider={provider as ProvidersType}
                name={provider}
              />
              <FontAwesomeIcon icon={faBars} width={15} height={15} />
            </div>
          </Reorder.Item>
        )
      })}
    </Reorder.Group>
  )
}

export default TeamsCouriersRankList
