import { DispatchRuleType } from "@/types/dispatchRules.types"

export const dispatchRulesPriority = [
  {
    title: "Cheapest",
    value: DispatchRuleType.PRICE,
    isEnabled: (isKosmoNetwork: boolean) => !isKosmoNetwork
  },
  {
    title: "Fastest",
    value: DispatchRuleType.ETA,
    isEnabled: (isKosmoNetwork: boolean) => !isKosmoNetwork
  },
  {
    title: "Ranking",
    value: DispatchRuleType.RANK,
    isEnabled: (_: boolean) => true
  },
  {
    title: "Zones",
    value: DispatchRuleType.ZONE,
    isEnabled: (_: boolean) => true
  }
]
