import React from "react"
import { ProvidersType } from "@/types/providers.types"
import { DisplayProvider } from "@/UI"
import { useTranslation } from "react-i18next"

interface ProviderRankCardProps {
  rank: number
  provider: ProvidersType
  type: "provider" | "team"
  name: string
  grab?: boolean
}

const ProviderRankCard: React.FC<ProviderRankCardProps> = ({
  rank,
  provider,
  name,
  type,
  grab = false,
}) => {
  const { t } = useTranslation()
  return (
    <div className="flex items-center space-x-3">
      <p>
        {t("common.rank")} {rank}
      </p>
      <div className={`${grab ? "cursor-grab" : ""}`}>
        {type === "provider" ? (
          <DisplayProvider provider={provider} fontStyle={"font-normal"} />
        ) : (
          <p className="font-semibold">{name}</p>
        )}
      </div>
    </div>
  )
}

export default ProviderRankCard
