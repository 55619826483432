import ModalCard from "@/UI/Modals/ModalCard"
import CreateOrUpdateRuleForm from "../CreateOrUpdateRuleForm.tsx/CreateOrUpdateRuleForm.container"
import { useTranslation } from "react-i18next"
import React, { ReactElement, useState } from "react"
import { isPayingUserSelector } from "@/atoms/userAtom"
import { PROFILE_PAGE_PATH } from "@/constants/routes"
import { useSandboxRouter } from "@/hooks/useSandboxRouter"
import PreventWithModal from "@/UI/PreventWithModal"
import { useRecoilValue } from "recoil"

interface RuleFormModalButtonProps {
  button: ReactElement
}

const RuleFormModalButton: React.FC<RuleFormModalButtonProps> = ({ button }) => {
  const { t } = useTranslation()
  const isPayingUser = useRecoilValue(isPayingUserSelector)
  const { sandboxRouter } = useSandboxRouter()
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const openModal = () => {
    setIsModalVisible(true)
  }

  const closeModal = () => {
    setIsModalVisible(false)
  }

  if (!isPayingUser) {
    return (
      <PreventWithModal
        enable={!isPayingUser}
        label={t("onboarding.freePlanBannerDescription")}
        cta={{
          label: t("profile.planCard.startNow"),
          onClick: () => sandboxRouter.push(`${PROFILE_PAGE_PATH}/?p=plans`),
        }}
      >
        {button}
      </PreventWithModal>
    )
  }

  return (
    <>
      {React.cloneElement(button, { onClick: openModal })}
      <ModalCard
        closable
        isModalVisible={isModalVisible}
        onCancel={closeModal}
        title={t("profile.EmptySettingBox.createNewRule")}
        style={{ zIndex: 3000 }}
      >
        <CreateOrUpdateRuleForm closeModal={closeModal} type="create" />
      </ModalCard>
    </>
  )
}

export default RuleFormModalButton
